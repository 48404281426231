import moment from 'moment';
import React from 'react';

import './TourStop.css';

const Movie = ({ date, subTitle, thumbnail, title }) => {
  const formattedDate = moment(date).format('ddd MMM Do');

  return (
    <div className="tour-stop">
      <div className="tour-avatar">
        <img src={thumbnail} alt={title} />
      </div>
      <div className="tour-text">
        <div className="line1">{title}</div>
        {subTitle && <div className="line2">{subTitle}</div>}
        {date && <div className="line3">{formattedDate}</div>}
      </div>
    </div>
  );
};

export default Movie;
