import urlRegex from 'url-regex';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userAvatarSelector, userNameSelector, userIdSelector } from '../../selectors/users';
import { fetchMeta } from '../../services/whosup';
import tracker from '../../utils/tracker';

import './Message.css';
import UserAvatar from '../Outing/UserAvatar';

/* TODO:
 * Move meta fetch/cache to a service file
 */

const metaCache = {};

class MessageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasRichURL: false,
      newBody: '',
      richDesc: '',
      richFullURL: '',
      richImage: '',
      richTitle: '',
      richURL: '',
      source: '',
      type: '',
    };
  }

  componentDidMount() {
    this.parseBody();
  }

  parseBody = () => {
    const {
      data: { body },
    } = this.props;

    const matches = body && body.match(urlRegex());
    if (matches && matches.length) {
      const firstUrl = matches[0];
      const newBody = body.replace(firstUrl, '');
      this.setState({ newBody });
      this.fetchURLMetadata(firstUrl);
    }
  };

  fetchURLMetadata = async (url) => {
    const shortenedURL = url.split('/')[2];
    let meta = false;

    if (metaCache[url] || metaCache[url] === false) {
      meta = metaCache[url];
    } else {
      try {
        meta = await fetchMeta(url);
      } catch (error) {
        metaCache[url] = false;
      }
      metaCache[url] = meta;
    }

    let type;

    if (meta['og:image'] && meta['og:image'].includes('.ico')) {
      type = 'site';
    }

    if (meta && meta['og:site_name']) {
      this.setState({
        hasRichURL: true,
        richDesc: meta['og:description'],
        richFullURL: url,
        richImage: meta['og:image'],
        richTitle: meta['og:title'],
        richURL: shortenedURL,
        source: meta['og:site_name'],
        type,
      });
    }
  };

  handleClickOut = (ev) => {
    const { richTitle } = this.state;
    const { target } = ev;
    tracker.track('URL click-out from Message stream', {
      Title: richTitle,
      URL: target.href,
    });
  };

  handleRichContent = () => {
    const { richImage, richTitle, richURL, richFullURL, richDesc, source, type } = this.state;

    let content;

    if (type === 'site') {
      content = (
        <div className="message-rich-site-url-container">
          <div className="message-rich-site-url">
            <span className="message-rich-url-favicon">
              <img src={richImage} alt={richTitle} />
            </span>
            <span className="message-rich-site-url-text">{richURL}</span>
          </div>
          <a
            className="message-rich-site-url-link"
            href={richFullURL}
            onClick={this.handleClickOut}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="message-rich-site-title">{richTitle}</div>
          </a>
          <div className="message-rich-desc">{richDesc}</div>
        </div>
      );
    } else if (richFullURL) {
      content = (
        <a
          className="message-rich-url-link"
          href={richFullURL}
          onClick={this.handleClickOut}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="message-rich-url-container">
            <div className="message-rich-image">
              <img src={richImage} alt={richTitle} />
            </div>
            <div className="message-rich-text">
              <div className="message-rich-title">{richTitle}</div>
              <div className="message-rich-url">{richURL}</div>
            </div>
          </div>
        </a>
      );
    }

    return content;
  };

  handleAvatar() {
    const { avatar, name } = this.props;
    const initial = name ? name.slice(0, 1) : '';
    const isDefault = avatar ? avatar.includes('avatar-guest') : false;

    if (isDefault) {
      return <div className="message-avatar empty">{initial}</div>;
    }

    return <UserAvatar customStyle="message-avatar" avatar={avatar} />;
  }

  render() {
    const {
      data: { timestamp, photo, body, user },
      name,
      currentUserId,
    } = this.props;
    const { hasRichURL, newBody } = this.state;
    const bodyFragment = hasRichURL ? newBody : body;
    const isCurrentUser = user === currentUserId;
    return (
      <div className={`message ${isCurrentUser ? 'right' : ''}`}>
        <div>{this.handleAvatar()}</div>
        <div className={`message-content ${isCurrentUser ? 'right' : ''}`}>
          <div className="message-details">
            <span className={`message-name ${isCurrentUser ? 'current' : ''}`}>{name}</span>{' '}
          </div>
          <div className="message-body">
            <div className="message-text">{bodyFragment}</div>
            {photo && (
              <div className="message-photo-container">
                <img className="message-photo" src={photo} alt={photo} />
              </div>
            )}
            {this.handleRichContent()}
          </div>
          <div className="message-timestamp">{moment(timestamp).format('LT')}</div>
        </div>
        {/* {isCurrentUser && <div>{this.handleAvatar()}</div>} */}
      </div>
    );
  }
}

const mapStateToProps = (state, { data }) => ({
  avatar: userAvatarSelector(state, data),
  currentUserId: userIdSelector(state),
  name: userNameSelector(state, data),
});

export default connect(mapStateToProps)(MessageComponent);
