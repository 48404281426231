import axios from 'axios';

const WHOSUP_API = 'https://app.fantic.io';

export const addSuggestionToOuting = async ({ outingId, selectedSuggestion, userToken }) =>
  axios.put(`${WHOSUP_API}/outing/${outingId}/suggestions`, {
    selectedSuggestion,
    userToken,
  });

export const adminLogin = async ({ password }) => {
  const { data } = await axios.post(`${WHOSUP_API}/admin/login`, {
    password,
  });

  return data;
};

export const createOutingShare = async ({ outingId, userToken }) => {
  const { data } = await axios.post(`${WHOSUP_API}/outing/share`, {
    outingId,
    userToken,
  });

  return data && data.url;
};

export const createSuggestion = async ({ outingId, suggestions, userToken }) => {
  const { data } = await axios.post(`${WHOSUP_API}/outing/${outingId}/suggestions`, {
    suggestions,
    userToken,
  });

  return data;
};

export const createUser = ({ createdBy = 'widget', firstName, lastName, userToken }) =>
  axios.post(`${WHOSUP_API}/newUsers`, {
    createdBy,
    firstName,
    lastName,
    type: 'outOfApp',
    userToken,
  });

export const fetchEvent = async (eventId) => {
  const { data } = await axios.get(`${WHOSUP_API}/event/${eventId}`);

  return data;
};

export const fetchMaObject = async (token) => {
  const { data } = await axios.get(`${WHOSUP_API}/ma/${token}`);

  return data;
};

export const fetchMeta = async (url) => {
  const { data } = await axios.post(`${WHOSUP_API}/metaScraper`, { url });

  return data;
};

export const fetchOutingShare = async (outingShare) => {
  const { data } = await axios.get(`${WHOSUP_API}/outing/share/${outingShare}`);

  return data;
};

export const joinOutingShare = async ({ outingShare, userToken }) => {
  const { data } = await axios.put(`${WHOSUP_API}/outing/share/${outingShare}`, {
    userToken,
  });

  return data && data.url;
};

export const search = async (options) => {
  const { data } = await axios.post(`${WHOSUP_API}/search`, options);

  return data;
};

export const uploadData = async ({ type, payload, onUploadProgress = () => {} }) => {
  const { data } = await axios.post(
    `${WHOSUP_API}/upload`,
    {
      data: payload,
      type,
    },
    { onUploadProgress },
  );

  return data && data.url;
};

export const updateOauth = async ({ code, provider, userToken }) => {
  const { data } = await axios.post(`${WHOSUP_API}/user/oauth`, {
    code,
    provider,
    userToken,
  });

  return data;
};

export const watchParty = async ({ outingId, userToken }) => {
  const { data } = await axios.post(`${WHOSUP_API}/outing/${outingId}/watchParty`, {
    userToken,
  });
};
