import axios from 'axios';
import moment from 'moment';
import React, { Component, Fragment } from 'react';

import { search } from '../../services/whosup';

import './MovieTimes.css';

/* TODO:
 * Move GOOGLE_KEY to shared file
 * Use moment or another non-Date library for date related logic
 * Refactor to DRY up date logic
 * See if we can use a different, more relevant value for the default/fallback zip
 */

const GOOGLE_KEY = 'AIzaSyA1Tdwp04qLzv4rm7fg_CI29NHx6gyhUM8';

class MovieTimesComponent extends Component {
  constructor(props) {
    super(props);
    const today = new Date();
    this.state = {
      activeDate: `${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`,
      currZip: '',
      currZipDraft: '',
      selected: [],
      showTimesDate: this.setShowTimesDate(),
      times: [],
    };
  }

  componentDidMount() {
    this.askForZip();
  }

  setZip = (zip) => {
    const { showTimesDate } = this.state;
    this.setState(
      {
        currZip: zip,
        currZipDraft: zip,
      },
      () => {
        this.fetchTimes(showTimesDate);
      },
    );
  };

  setShowTimesDate = () => {
    const { info } = this.props;
    const today = new Date();

    if (info.releaseDate) {
      const releaseDate = new Date(info.releaseDate);
      if (releaseDate.getTime() > today.getTime()) {
        releaseDate.setDate(releaseDate.getDate() - 1);
        return `${releaseDate.getMonth() + 1}-${releaseDate.getDate()}-${releaseDate.getFullYear()}`;
      }
    }
    return `${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`;
  };

  askForZip = () => {
    const { detail } = this.props;
    if (detail.address) {
      const addressArr = detail.address.split(' ');
      const potentialZip = addressArr[addressArr.length - 1];
      if (Number.isInteger(potentialZip)) {
        this.setZip(potentialZip);
      }
    }

    // if (!navigator.geolocation) {
    this.setZip('90010');
    // } else {
    //   try {
    //     navigator.geolocation.getCurrentPosition(async position => {
    //       const {
    //         coords: { latitude, longitude },
    //       } = position;
    //       if (!(latitude && longitude)) {return}
    //       const { data } = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=${GOOGLE_KEY}`);
    //       if (data && Array.isArray(data.results)) {
    //         const result = data.results[0];
    //         const zips = result.address_components.filter(component => component.types[0] === 'postal_code');
    //         if (zips.length) {
    //           const zip = zips[0].long_name;
    //           this.setZip(zip);
    //         }
    //       }
    //     }, (error) => {
    //       console.log(error);
    //       this.setZip('90010');
    //     });
    //   } catch(error) {
    //     console.log(error);
    //     this.setZip('90010');
    //   }
    // }
  };

  fetchTimes = async (date) => {
    const {
      detail: { externalId, externalService },
    } = this.props;
    const { currZip } = this.state;
    this.setState({ activeDate: date, times: [] });

    let data = {};
    try {
      let params = {
        date,
        id: externalId,
        postalCode: currZip,
        type: ['fandango'],
      };
      if (externalService === 'fantic') {
        params = {
          query: externalId,
          type: ['fantic'],
        };
      }
      data = await search(params);
    } catch (error) {
      console.error(error.stack);
    }
    this.setState({ times: data.results || [] });
  };

  buildSelectedFormat = () => {
    const { exit } = this.props;
    const { selected } = this.state;
    // const result = {};

    // for (let i = 0; i < selected.length; i += 1) {
    //   const { activeDate, showtime } = selected[i];
    //   const theater = selected[i].theater.replace(/(\.|\[|\]|#|\$|\/)+/g, '');

    //   if (result[activeDate]) {
    //     if (result[activeDate][theater]) {
    //       result[activeDate][theater][showtime] = true;
    //     } else {
    //       result[activeDate][theater] = {
    //         [showtime]: true,
    //       };
    //     }
    //   } else {
    //     result[activeDate] = {
    //       [theater]: {
    //         [showtime]: true,
    //       },
    //     };
    //   }
    // }

    const result = [];
    for (let i = 0; i < selected.length; i += 1) {
      const { activeDate, showtime, address, city, latitude, longitude, state, tickets, zip } = selected[i];
      const theater = selected[i].theater.replace(/(\.|\[|\]|#|\$|\/)+/g, '');

      result.push({
        date: moment(`${activeDate} ${showtime}`, 'MM-DD-YYYY h:mm A').toISOString(),
        location: { address, city, latitude, longitude, state, zip },
        tickets,
        venueName: theater,
      });
    }

    exit(result);
  };

  hasBeenSelected = (theater, showtime) => {
    const { selected, activeDate } = this.state;

    for (let i = 0; i < selected.length; i += 1) {
      if (
        selected[i].activeDate === activeDate &&
        selected[i].theater === theater &&
        selected[i].showtime === showtime
      ) {
        return i + 1;
      }
    }

    return false;
  };

  handleSuggest = (theater, showtime, location) => {
    const { selected, activeDate } = this.state;
    const selectedIdx = this.hasBeenSelected(theater, showtime.time);
    const { street, city, latitude, longitude, state, zip } = location;

    if (selectedIdx) {
      const result = [...selected.slice(0, selectedIdx - 1), ...selected.slice(selectedIdx)];
      this.setState({ selected: result });
      return;
    }

    if (selected.length === 5) {
      return;
    }

    this.setState({
      selected: [].concat(selected, [
        {
          activeDate,
          address: street,
          city,
          latitude,
          longitude,
          showtime: showtime.time,
          state,
          theater,
          tickets: showtime.tickets,
          zip,
        },
      ]),
    });
  };

  handleSelect = (theater, showing) => {
    const { exit } = this.props;
    const { activeDate } = this.state;
    exit(activeDate, theater, showing);
  };

  handleZip = (e) => {
    const { value } = e.target;
    this.setState({ currZipDraft: value });
  };

  renderDates = () => {
    const { activeDate, showTimesDate } = this.state;
    const dates = [];

    for (let i = 0; i < 8; i += 1) {
      const showtime = moment(showTimesDate, 'MM-DD-YYYY').add(i, 'd');
      const text = showtime.calendar(null, {
        lastWeek: 'ddd, MMM D',
        nextWeek: 'ddd, MMM D',
        sameElse: 'ddd, MMM D',
      });
      dates.push({
        text,
        value: showtime.format('M-D-YYYY'),
      });
    }

    return (
      <div className="date-picker-container">
        {dates.map((date) => (
          <div
            className={`date-picker-item-container ${activeDate === date.value ? 'date-picker-item-active' : ''}`}
            key={date.value}
            onClick={() => this.fetchTimes(date.value)}
          >
            {date.text}
          </div>
        ))}
      </div>
    );
  };

  renderZip = () => {
    const { currZip, currZipDraft, activeDate } = this.state;

    return (
      <div className="zip-picker-container">
        Near: <input type="number" value={currZipDraft} maxLength="5" onChange={this.handleZip} />
        {currZip !== currZipDraft && (
          <button
            onClick={() => {
              if (currZipDraft.length !== 5) {
                return;
              }
              this.setState({ currZip: currZipDraft }, () => {
                this.fetchTimes(activeDate);
              });
            }}
            type="button"
          >
            save
          </button>
        )}
      </div>
    );
  };

  render() {
    const { hideTooltip, showSelectShowtimesTooltip, type } = this.props;
    const { times, selected } = this.state;

    let selectShowtimesTooltip;
    if (showSelectShowtimesTooltip) {
      selectShowtimesTooltip = (
        <div className="fullscreen-tooltip-container" onClick={hideTooltip}>
          <div className="fullscreen-tooltip">Select up to 5 showtimes for your friends to vote on.</div>
        </div>
      );
    }

    return (
      <Fragment>
        {selectShowtimesTooltip}
        {/* {this.renderDates()}
        {this.renderZip()} */}
        {times.length > 0 ? (
          times.map((theater, idx) => {
            if (idx > 15 || !theater.showings) {
              return '';
            }
            return (
              <div key={theater.id} className="theater-item-container">
                <div className="theater-item-header">
                  <div className="theater-item-title">{theater.title}</div>
                  {theater.distance && (
                    <div className="theater-item-distance">{Number(theater.distance).toFixed(2)} mi</div>
                  )}
                </div>

                <div className="showing-item-container">
                  {theater.showings.map((showing) => (
                    <div
                      className={`showing-item-times ${
                        this.hasBeenSelected(theater.title, showing.time) ? 'showing-item-times-active' : ''
                      }`}
                      key={showing.id}
                      onClick={() => {
                        if (type === 'select') {
                          this.handleSelect(theater, showing);
                        } else if (type === 'suggest') {
                          this.handleSuggest(theater.title, showing, theater.location);
                        }
                      }}
                    >
                      {showing.time}
                    </div>
                  ))}
                </div>
              </div>
            );
          })
        ) : (
          <div className="loading">Finding showtimes...</div>
        )}
        {selected.length > 0 && (
          <div className="button suggest-button" onClick={this.buildSelectedFormat}>
            Suggest Selected Times
          </div>
        )}
      </Fragment>
    );
  }
}

export default MovieTimesComponent;
