import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFirebase, isEmpty } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';

import Loading from './Loading';
import LoadFirebaseOuting from './LoadFirebaseOuting';
import OutOfApp from './OutOfApp';
import { fetchMaObject } from '../../services/whosup';
import tracker from '../../utils/tracker';

class OutOfAppAuth extends Component {
  state = {
    redirect: false,
  };

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate({ match: oldMatch }) {
    const { match: newMatch } = this.props;
    if (oldMatch.params.token !== newMatch.params.token) {
      this.setup();
    }
  }

  async setup() {
    const { match } = this.props;

    try {
      const valid = await this.checkValidToken(match.params.token);
      if (!valid) {
        this.setState({ redirect: true });
      }
    } catch (e) {
      console.error('checkValidToken', e);
    }
  }

  trackFirstClick = () => {
    const { auth, firebase } = this.props;
    const userId = auth.displayName || auth.uid;

    return firebase.database().ref(`users/${userId}/settings/seenOutingTooltips`).set(true);
  };

  async checkValidToken(token) {
    const { auth, firebase, selectOuting } = this.props;

    let data = {};
    try {
      data = await fetchMaObject(token);
    } catch (error) {
      console.error(error.stack);
      return false;
    }

    if (data.errorMessage) {
      return false;
    }

    const userId = auth.displayName || auth.uid;

    if (auth && data.user !== userId) {
      await firebase.auth().signOut();
    }
    if (isEmpty(auth)) {
      await firebase.auth().signInWithCustomToken(data.firebaseToken);
    }

    selectOuting(data.outing);

    tracker.register({
      'Outing Viewed': data.outing,
    });
    tracker.track('View Outing');
    tracker.identify(data.user);

    return true;
  }

  render() {
    const { auth, selectedOuting } = this.props;
    const { redirect } = this.state;
    const userId = auth.displayName || auth.uid;

    if (auth && selectedOuting && auth.isLoaded && !auth.isEmpty) {
      return (
        <LoadFirebaseOuting outing={selectedOuting} user={userId}>
          <OutOfApp trackFirstClick={this.trackFirstClick} />
        </LoadFirebaseOuting>
      );
    }

    if (redirect) {
      return <Redirect to="/" />;
    }

    return <Loading />;
  }
}

const mapDispatchToProps = {
  selectOuting: (outing) => ({ outing, type: 'SELECT_OUTING' }),
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  selectedOuting: state.selectedOuting,
});

export default compose(withFirebase, connect(mapStateToProps, mapDispatchToProps))(OutOfAppAuth);
