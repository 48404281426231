import React from 'react';

import './Loading.css';

import logo from '../../images/fanticlogo.png';
import loadingDots from '../../images/loading_dots.gif';

const LoadingComponent = () => (
  <div className="loading-container">
    <div className="loading-content center">
      {/* <img className="logo" src={logo} alt="logo" /> */}
      <img className="loading-dots" src={loadingDots} alt="loading-dots" />
    </div>
  </div>
);

export default LoadingComponent;
