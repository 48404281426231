import React from 'react';
import GoogleProvider from './Providers/Google';

import WatchPartyButton from '../Outing/WatchPartyButton';

const Methods = ({ firebase }) => (
  <div>
    <GoogleProvider />
  </div>
);

export default Methods;
