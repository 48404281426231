import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import {
  outingDateSelector,
  outingDetailSelector,
  outingHasShowtimesSelector,
  outingPartnerSelector,
  outingTypeSelector,
} from '../../selectors/outings';
import tracker from '../../utils/tracker';

import './Send.css';

class SendActionsComponent extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.ref.current.focus();
  }

  cleanUp = () => {
    const { dispatch, close } = this.props;
    close();
  };

  render() {
    const {
      dispatch,
      close,
      outingType,
      outingDate,
      outingHasLocation,
      outingPartner,
      outingHasShowtimes,
    } = this.props;
    return (
      <div ref={this.ref} onBlur={() => close()} tabIndex="-1" style={{ outline: 'none' }}>
        <div
          className="send-action"
          onClick={() => {
            tracker.track('Add User');
            dispatch({ source: 'cover', type: 'OPEN_ADD_FLOW' });
          }}
        >
          Invite Friends
        </div>
        <div className="subtitle" />
        {outingPartner && outingHasShowtimes === false ? (
          <div className="send-action-no-showtimes">
            <h4>Showtimes</h4>
            Showtimes and Theatres are not available yet. We’ll let you know when they are. You can then suggest
            showtimes, theatres, food and drinks to the group.
          </div>
        ) : (
          <Fragment>
            {false && outingHasLocation && (
              <Fragment>
                <div
                  className="send-action"
                  onClick={() => {
                    dispatch({ type: 'OPEN_SUGGEST_MEET_UP' });
                    this.cleanUp();
                    tracker.track('Suggest Meet Up');
                  }}
                >
                  Suggest Meet Up Spot
                </div>
                {outingType === 'movie' && <div className="subtitle" />}
              </Fragment>
            )}
            {outingType === 'movie' && (
              <Fragment>
                <div
                  className="send-action"
                  onClick={() => {
                    dispatch({ type: 'OPEN_SELECT_MOVIE_TIME' });
                    this.cleanUp();
                    tracker.track(`${outingDate ? 'Edit' : 'Add'} Showtimes`, { Action: 'Open' });
                  }}
                >
                  {/* Select a
                  {outingDate ? ' New ' : ' '}
                  Showtime */}
                  View Showtimes
                </div>
                {/* <div
                  className="send-action"
                  onClick={() => {
                    dispatch({ type: 'OPEN_SUGGEST_MOVIE_TIMES' });
                    this.cleanUp();
                    tracker.track('Suggest Showtimes', { Action: 'Open' })
                  }}
                >
                  Suggest Showtimes
                </div> */}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  outingDate: outingDateSelector(state, props),
  outingHasLocation: outingDetailSelector(state, props).latitude && outingDetailSelector(state, props).longitude,
  outingHasShowtimes: outingHasShowtimesSelector(state, props),
  outingPartner: outingPartnerSelector(state, props),
  outingType: outingTypeSelector(state, props),
});

export default connect(mapStateToProps)(SendActionsComponent);
