import React, { useRef, useState } from 'react';
import loadingDots from '../../images/loading_dots.gif';
import { uploadData } from '../../services/whosup';
import Event from './Event';

const MB = 1000000;

const EventUploadBody = ({ event }) => {
  const [allDone, setAllDone] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const filesRef = useRef(null);

  let details = {};
  if (event.type === 'concert' && event.detail) {
    details = {
      date: event.date,
      performers: event.detail.performers,
      venue: {
        name: event.detail.venueName,
      },
    };
  }

  const checkFiles = () => {
    const files = Array.from(filesRef.current.files);
    filesRef.current.value = '';
    setErrorMsg('');

    const tooBig = [];
    const good = [];
    files.forEach((file) => {
      if (file.type.indexOf('image/') === -1) {
        return;
      }
      if (file.size > 10 * MB) {
        tooBig.push(file.name);
        return;
      }

      good.push(file);
    });

    if (good.length !== 1) {
      setErrorMsg('Please select your best image under 10MB.');
      good.length = 0;
    }

    if (tooBig.length) {
      setErrorMsg(`The file ${tooBig.join(', ')} is larger than 10MB`);
    }

    setSelectedFiles(good);
  };

  const handleSubmit = async () => {
    setIsUploadingFiles(true);
    let userId = 'anon';

    try {
      userId = window.firebase.auth().currentUser.displayName;
    } catch (e) {
      console.error('User not logged in.', e);
    }

    const promises = selectedFiles.map(
      (file) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const finalFile = reader.result.split(',')[1];
            await uploadData({ payload: finalFile, type: `event/${event.id}/${userId}` });
            resolve();
          };

          reader.readAsDataURL(file);
        }),
    );
    await Promise.all(promises);
    setAllDone(true);
    setIsUploadingFiles(false);
  };

  return (
    <div className="details-body upload">
      <Event details={details} show={['artist', 'date', 'venue']} />
      {!allDone && (
        <>
          <p>Share your best photo from the show!</p>
          {selectedFiles.length === 0 && (
            <label className="upload" htmlFor="files">
              Select {selectedFiles.length > 0 && 'New'} Photo
              <input type="file" name="files" id="files" onChange={checkFiles} ref={filesRef} accept="image/*" />
            </label>
          )}
          <div className="error-message">{errorMsg}</div>
          <div className="filesList">
            {selectedFiles.map((file) => (
              <div key={file.name}>
                {file.name} - {(file.size / MB).toFixed(2)} MB
              </div>
            ))}
          </div>
          {selectedFiles.length > 0 && (
            <>
              <small>
                When submitting your photo you agree to Fantic&apos;s <a href="https://www.fantic.io/terms">Terms</a>{' '}
                and
                <a href="https://www.fantic.io/privacy">Privacy Policy</a>
              </small>
              <button className="upload" type="button" onClick={handleSubmit} disabled={isUploadingFiles}>
                {isUploadingFiles ? (
                  <img className="loading-dots" src={loadingDots} alt="loading-dots" />
                ) : (
                  'Submit Photo'
                )}
              </button>
            </>
          )}
        </>
      )}
      {allDone && <div className="done">Thank you for your submission!</div>}
    </div>
  );
};
export default EventUploadBody;
