import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';

import LocationMap from './Details/LocationMap';
import { outingDetailSelector, outingIsLivestreamSelector } from '../../selectors/outings';
import tracker from '../../utils/tracker';

const OutingEventDetailsComponent = ({ detail, isLivestream }) => {
  if (isEmpty(detail)) {
    return null;
  }

  const { address, city, state, phone, latitude, longitude, venueName, theater, meetup } = detail;

  const handleUberClick = () => {
    tracker.track('Uber');
  };

  return (
    <div className="oed">
      {meetup && (
        <Fragment>
          <div className="detail-row">
            <div className="detail-row-content">
              <div className="detail-row-title">Meet Up Location</div>
            </div>
            <LocationMap latitude={meetup.latitude} longitude={meetup.longitude} />
            {meetup.venueName && (
              <Fragment>
                <div className="detail-row-content">
                  <div>
                    <div className="detail-row-title">Venue</div>
                    {meetup.venueName}
                  </div>
                </div>
                <div className="detail-row-border" />
              </Fragment>
            )}
            {meetup.address && (
              <Fragment>
                <div className="detail-row-content">
                  <div className="detail-row-title">Address</div>
                  {meetup.address}
                  <br />
                  {meetup.city && <span>{meetup.city}</span>}
                  {meetup.state && <span>, {meetup.state}</span>}
                </div>
                <div className="detail-row-border" />
              </Fragment>
            )}
          </div>
          {meetup.address && (
            <div className="detail-row">
              <div className="detail-row-content">
                <a
                  className="detail-link"
                  href={`https://m.uber.com/ul/?action=setPickup&dropoff[latitude]=${meetup.latitude}&dropoff[longitude]=${meetup.longitude}`}
                  onClick={handleUberClick}
                >
                  Ride there with Uber
                </a>
              </div>
            </div>
          )}
        </Fragment>
      )}

      {!isLivestream && latitude && longitude && (
        <div className="detail-row">
          <div className="detail-row-content">
            <div className="detail-row-title">Venue Location</div>
          </div>
          <LocationMap latitude={latitude} longitude={longitude} />
          {venueName && (
            <Fragment>
              <div className="detail-row-content">
                <div>
                  <div className="detail-row-title">Venue</div>
                  {venueName}
                </div>
              </div>
              <div className="detail-row-border" />
            </Fragment>
          )}
          {theater && (
            <Fragment>
              <div className="detail-row-content">
                <div>
                  <div className="detail-row-title">Theater</div>
                  {theater}
                </div>
              </div>
              <div className="detail-row-border" />
            </Fragment>
          )}
          {address && (
            <Fragment>
              <div className="detail-row-content">
                <div className="detail-row-title">Address</div>
                {address.split('\n').map((line) => (
                  <span key={line}>
                    {line}
                    <br />
                  </span>
                ))}
                {/* {city && <span>{city}</span>}
                  {city && state && <span>, </span>}
                  {state && <span>{state}</span>} */}
              </div>
              <div className="detail-row-border" />
            </Fragment>
          )}
        </div>
      )}
      {phone && (
        <div className="detail-row">
          {phone && (
            <div className="detail-row-content">
              <div className="detail-row-title">Phone</div>
              {phone}
            </div>
          )}
          <div className="detail-row-border" />
        </div>
      )}
      {/* {address && (
        <div className="detail-row">
          <div className="detail-row-content">
            <a
              className="detail-link"
              href={`https://m.uber.com/ul/?action=setPickup&dropoff[latitude]=${latitude}&dropoff[longitude]=${longitude}`}
              onClick={handleUberClick}
            >
              Ride there with Uber
            </a>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default connect((state) => ({
  detail: outingDetailSelector(state),
  isLivestream: outingIsLivestreamSelector(state),
}))(OutingEventDetailsComponent);
