import Clipboard from 'clipboard';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { compose } from 'redux';

import InviteCreated from './InviteCreated';
import { userIdSelector } from '../../selectors/users';
import { createOutingShare } from '../../services/whosup';
import { generateLink, generateMessage } from '../../utils/outingShare';
import tracker from '../../utils/tracker';
import { isFacebook, isMobile } from '../../utils/userAgent';
import { fanticUrl } from '../../utils/fanticUrl';

import './CreateOuting.css';

let copy;

export class CreateOutingComponent extends Component {
  constructor() {
    super();
    this.state = {
      currentAction: 'Creating your Fantic user',
      redirectLink: '',
      userInfo: null,
    };
  }

  async UNSAFE_componentWillMount() {
    const { details, firebase, userId } = this.props;
    const {
      metadata: { creationTime },
    } = firebase.auth().currentUser;
    const now = new Date().toISOString();
    let detail;

    if (details.type === 'movie') {
      detail = {
        ...details.detail,
        address: `${details.detail.address}, ${details.detail.state} ${details.detail.zip}`,
        externalId: details.externalId || details.id || details.partner,
        externalService: details.externalService || 'fantic',
        street: details.detail.address,
      };
    } else if (details.type === 'tour' || details.type === 'ticketmaster') {
      const addressObj = {};
      if (details.address && details.address.street) {
        addressObj.address = details.address.street;
        addressObj.city = details.address.city;
        addressObj.state = details.address.state;
        addressObj.street = details.address.street;
      }
      detail = {
        ...addressObj,
        externalId: details.id,
        externalService: details.type,
        links: details.tickets
          ? [
              {
                description: 'Buy tickets from Ticketmaster',
                type: 'Tickets',
                url: details.tickets[0],
              },
            ]
          : [],
      };
      if (details.location) {
        detail.latitude = details.location && parseFloat(details.location.latitude);
        detail.longitude = details.location && parseFloat(details.location.longitude);
      }
      if (details.venue) {
        detail.venueId = details.venue && details.venue.id;
        detail.venueName = details.venue && details.venue.name;
      }
    } else if (details.type === 'concert') {
      detail = {
        ...details.detail,
        externalId: details.id,
        externalService: 'WhosUp',
      };
    }
    const info = {
      creationDate: now,
      date: details.date || '',
      host: userId,
      members: {
        [userId]: {
          addedBy: userId,
          invited: now,
          status: 'accepted',
        },
      },
      title: details.title,
      type: details.type ? details.type : 'concert',
      venueImage: details.image,
    };
    if (details.customCopy) {
      info.customCopy = details.customCopy;
    }
    if (details.subType) {
      info.subType = details.subType;
    }
    let firstMessage;
    if (details.id && details.partner) {
      info.id = details.id;
      info.partner = details.partner;
    }
    if (details.type === 'movie') {
      info.header = details.header;
      info.image = details.image;
      info.tagline = details.tagline;
      info.hasShowtimes = details.hasShowtimes || false;
      info.richMediaImage = details.richMediaImage || details.image;
      info.thumbnail = details.thumbnail;
      info.movieTitle = details.movieTitle || details.title;
      info.releaseDate = details.releaseDate || '';

      if (details.detail.theater) {
        info.theater = details.detail.theater;
      }
      if (details.richMediaText) {
        info.richMediaText = details.richMediaText;
      }
      if (details.inviteText) {
        info.inviteText = details.inviteText;
      }
      if (details.video) {
        info.video = details.video;
      }
      if (details.inviteImage) {
        info.inviteImage = details.inviteImage;
      }
      if (details.partner !== 'goldenarm' && details.hasShowtimes) {
        info.date = '';
      }
      if (details.senderMessage) {
        info.senderMessage = details.senderMessage;
      }
      if (details.senderId) {
        info.senderId = details.senderId;
      }
      if (details.subTitle) {
        info.subTitle = details.subTitle;
      }
      if (details.strictTimeZone) {
        info.strictTimeZone = details.strictTimeZone;
      }

      firstMessage = details.senderMessage || `Watch this!\n\n${details.video}`;
    } else if (details.type === 'concert') {
      info.header = details.header;
      info.image = details.image;
      info.richMediaImage = details.richMediaImage || details.image;
      if (details.senderId) {
        info.senderId = details.senderId;
      }
      if (details.senderMessage) {
        info.senderMessage = details.senderMessage;
      }
      info.thumbnail = details.thumbnail;
      if (details.video) {
        info.video = details.video;
      }

      firstMessage = details.senderMessage || `Who's up for ${details.title}?`;
    } else {
      if (details.senderMessage) {
        info.senderMessage = details.senderMessage;
      }

      firstMessage = details.senderMessage || `Who's up for ${details.title}?`;
    }
    const outing = {
      detail,
      info,
      messages: {
        '-00000': {
          body: firstMessage,
          muted: true,
          timestamp: now,
          user: details.senderId || userId,
        },
      },
    };
    const snapshot = await firebase.database().ref('outings').push(outing);
    const outingId = snapshot.key;
    this.setAction('Creating your private group');

    let userInfo;
    let userToken;
    let url;
    try {
      userToken = await firebase.auth().currentUser.getIdToken(true);
      url = await createOutingShare({
        outingId,
        userToken,
      });
      userInfo = (await firebase.database().ref(`users/${userId}/info`).once('value')).val();
      this.setState({
        userInfo,
      });
    } catch (error) {
      console.error(error.stack);
      return;
    }

    tracker.register({
      'Date Outing Created': info.creationDate,
      'Outing Creator Name': userId,
      'Outing Location': detail.venueName || 'None',
    });
    tracker.people({
      $created: creationTime,
      $first_name: userInfo.firstName,
      $last_login: new Date(),
      $last_name: userInfo.lastName,
      'Account Create Date': creationTime,
      Device: navigator.userAgent,
      'Last Open Date': new Date(),
      'User ID': userId,
    });
    tracker.increment({
      'Outings Created': 1,
    });
    this.handleRedirect(url);
  }

  componentDidMount() {
    copy = new Clipboard('.copy');
    copy.on('success', () => {
      this.setAction('Link Copied to your Clipboard');
      setTimeout(() => {
        this.setAction('');
      }, 3000);
    });
  }

  setAction = (currentAction) => {
    this.setState({
      currentAction,
    });
  };

  handleRedirect = (url) => {
    const {
      details: { date, detail, movieTitle, releaseDate, title, type, venue, venueName },
      inviteType,
    } = this.props;
    const {
      userInfo: { firstName },
    } = this.state;

    let vName = (venue && venue.name) || venueName;
    if (!vName && detail) {
      vName = detail.venueName;
    }

    const message = generateMessage({
      date,
      firstName,
      releaseDate,
      title: movieTitle || title,
      type,
      url,
      venueName: vName,
    });
    const redirectLink = generateLink({
      inviteType,
      message,
      title: movieTitle || title,
      url,
    });

    this.setAction('');
    this.setState({
      message,
      redirectLink,
      url,
    });

    tracker.track('OOA Create: Outing Created');

    if (inviteType === 'skip') {
      window.setTimeout(() => {
        window.location.href = fanticUrl(redirectLink);
      }, 3000);
      this.setAction('Redirecting you to your private group in 3 seconds.');
    } else if (isMobile && !(isFacebook && inviteType === 'messenger')) {
      const newWindow = window.open(redirectLink, '_blank');
      if (!newWindow) {
        window.location.href = fanticUrl(redirectLink);
      }
    }
  };

  render() {
    const { currentAction, message, redirectLink, url } = this.state;
    const { inviteType } = this.props;
    return (
      <InviteCreated
        currentAction={currentAction}
        inviteType={inviteType}
        message={message}
        redirectLink={redirectLink}
        url={url}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userId: userIdSelector(state),
});

export default compose(withFirebase, connect(mapStateToProps))(CreateOutingComponent);
