import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, withFirebase } from 'react-redux-firebase';
import { compose } from 'redux';

import MovieTimes from './MovieTimes';
import { outingDetailSelector, outingInfoSelector, outingIdSelector } from '../../selectors/outings';
import { userSettingsSelector, userIdSelector } from '../../selectors/users';
import { createSuggestion } from '../../services/whosup';
import { scrollBottom } from '../../utils/scrolls';

class SuggestMovieTimesComponent extends Component {
  exit = async (data) => {
    const { dispatch, firebase, outing, user } = this.props;
    dispatch({ type: 'CLOSE_SUGGEST_MOVIE_TIMES' });

    const suggestions = data.map((updates) => ({
      createdAt: new Date().toISOString(),
      suggestedBy: user,
      type: 'showtime',
      updates,
    }));

    const userToken = await firebase.auth().currentUser.getIdToken(true);

    try {
      await createSuggestion({
        outingId: outing,
        suggestions,
        userToken,
      });
    } catch (error) {
      console.error(error.stack);
    }

    scrollBottom(200);
  };

  render() {
    const { detail, firebase, info, settings, user } = this.props;
    const showSelectShowtimesTooltip = !(settings && settings.seenSelectShowtimesTooltip);
    if (isEmpty(info) && isEmpty(detail)) {
      return '';
    }

    return (
      <MovieTimes
        detail={detail}
        info={info}
        type="suggest"
        exit={this.exit}
        showSelectShowtimesTooltip={showSelectShowtimesTooltip}
        hideTooltip={() => firebase.database().ref(`users/${user}/settings/seenSelectShowtimesTooltip`).set(true)}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  detail: outingDetailSelector(state, props),
  info: outingInfoSelector(state, props),
  outing: outingIdSelector(state),
  settings: userSettingsSelector(state, props),
  user: userIdSelector(state),
});

export default compose(withFirebase, connect(mapStateToProps))(SuggestMovieTimesComponent);
