import React from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { compose } from 'redux';

import { outingsSelector } from '../../selectors/outings';
import { userNameSelector } from '../../selectors/users';
import tracker from '../../utils/tracker';
import { isMobile } from '../../utils/userAgent';

import smsIcon from '../../images/Widget/text.svg';
import emailIcon from '../../images/Widget/email.svg';
import messengerIcon from '../../images/Widget/facebookMessenger.svg';
import whatsappIcon from '../../images/Widget/whatsapp.svg';

import avatar1 from '../../images/Event/avatar1.png';
import avatar2 from '../../images/Event/avatar2.png';
import avatar3 from '../../images/Event/avatar3.png';

import './Details.css';

const DetailsComponent = ({ firebase, hasOutings, nextPage, userName, link, closeModal, dispatch }) => {
  const handleSkip = () => {
    if (closeModal) {
      closeModal();
    } else {
      dispatch({ type: 'OPEN_COVER' });
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const logOut = async () => {
    tracker.track('Log Out from Create');
    await firebase.auth().signOut();
  };

  const next = (inviteType) => {
    nextPage(inviteType);
  };

  return (
    <div>
      <div className="details-shares">
        <div className="friendInvLine">The more, the merrier: invite your contacts!</div>
        {/* TODO: change img assets */}
        <div className="avatarContainer">
          <div className="avatarPics">
            <span className="avatarPic">
              <img src={avatar3}></img>
            </span>
            <span className="avatarPic">
              <img src={avatar2}></img>
            </span>
            <span className="avatarPic">
              <img src={avatar1}></img>
            </span>
          </div>
        </div>
        <div className="friendInvLine">Private Groups are no fun without friends or family</div>
        <div className="linkCopyDiv">
          <h2 className="linkCopy">{link}</h2>
          {/* TODO: fix copy to clipboard */}
          <div className="linkCopyButton">
            <button type="button" className="text" onClick={copyToClipboard}>
              Copy
            </button>
          </div>
        </div>
        <div className="friendInvLine">Invite Using:</div>
        <div className="icons-container">
          <button className="shareOption" onClick={() => next('sms')} type="button">
            <div className="icon-circle">
              <img src={smsIcon} alt="SMS" />
            </div>
            <span>Text Message</span>
          </button>
          {/* TODO: Add this later
          {isMobile && ( */}
          {/* )} */}
          <button className="shareOption" onClick={() => next('email')} type="button">
            <div className="icon-circle">
              <img src={emailIcon} alt="Email" />
            </div>
            <span>Email</span>
          </button>
          <button className="shareOption" onClick={() => next('whatsapp')} type="button">
            <div className="icon-circle">
              <img src={whatsappIcon} alt="Whatsapp" />
            </div>
            <span>WhatsApp</span>
          </button>
          <button className="shareOption" onClick={() => next('messenger')} type="button">
            <div className="icon-circle">
              <img src={messengerIcon} alt="Messenger" />
            </div>
            <span>Messenger</span>
          </button>
        </div>
        <div>
          <button className="skip" onClick={handleSkip} type="button">
            Skip for now
          </button>
        </div>
      </div>

      {/* TODO: Uncomment later {userName && (
        <div className="logoutContainer">
          <button className="logout" onClick={logOut} type="button">
            Not {userName}? Click here to log out.
          </button>
        </div>
      )} */}
    </div>
  );
};

const enhance = compose(
  withFirebase,
  connect((state) => ({
    hasOutings: Object.keys(outingsSelector(state)).length > 0,
    userName: userNameSelector(state),
  })),
);

export default enhance(DetailsComponent);
