import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, withFirebase } from 'react-redux-firebase';
import { compose } from 'redux';

import VenuesList from './VenuesList';
import { outingDetailSelector, outingCustomCopySelector, outingIdSelector } from '../../selectors/outings';
import { userSettingsSelector, userIdSelector } from '../../selectors/users';
import { createSuggestion } from '../../services/whosup';
import { scrollBottom } from '../../utils/scrolls';

class SuggestMeetUpComponent extends Component {
  exit = async (venue) => {
    const { dispatch, firebase, outing, user } = this.props;
    dispatch({ type: 'CLOSE_SUGGEST_MEET_UP' });

    const suggestions = [
      {
        createdAt: new Date().toISOString(),
        data: {
          moreInfo: venue.url,
        },
        suggestedBy: user,
        type: 'meetup',
        updates: {
          location: {
            address: venue.address.street,
            city: venue.address.city,
            latitude: venue.location.latitude,
            longitude: venue.location.longitude,
            state: venue.address.state,
            zip: venue.address.zip,
          },
          title: venue.title,
          venueImage: venue.thumbnail,
          venueName: venue.title,
        },
      },
    ];

    const userToken = await firebase.auth().currentUser.getIdToken(true);

    try {
      await createSuggestion({
        outingId: outing,
        suggestions,
        userToken,
      });
    } catch (error) {
      console.error(error.stack);
    }

    scrollBottom(200);
  };

  render() {
    const { customCopy, detail, firebase, settings, user } = this.props;
    if (isEmpty(detail)) {
      return '';
    }

    const showMeetupSuggestionTooltip = !(settings && settings.seenMeetupSuggestionTooltip);

    return (
      <div className="oed">
        <VenuesList
          closeTooltip={() => {
            firebase.database().ref(`users/${user}/settings/seenMeetupSuggestionTooltip`).set(true);
          }}
          detail={detail}
          exit={this.exit}
          showMeetupSuggestionTooltip={showMeetupSuggestionTooltip}
          meetupSuggestionCopy={customCopy.meetupSuggestion || 'Suggest a meet up spot before the show.'}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  customCopy: outingCustomCopySelector(state, props).tooltips,
  detail: outingDetailSelector(state, props),
  outing: outingIdSelector(state),
  settings: userSettingsSelector(state),
  user: userIdSelector(state),
});

export default compose(withFirebase, connect(mapStateToProps))(SuggestMeetUpComponent);
