import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, withFirebase } from 'react-redux-firebase';

import MovieTimes from './MovieTimes';
import { outingDetailSelector, outingIdSelector, outingInfoSelector } from '../../selectors/outings';
import { userIdSelector } from '../../selectors/users';
import { scrollBottom } from '../../utils/scrolls';

/* TODO:
 * Refactor exit so there aren't chain blocking async calls, use Promise.all([]) instead
 */

class SelectMovieTimeComponent extends Component {
  exit = async (date, theater, showing) => {
    const { dispatch, firebase, outing, user } = this.props;
    const dateArr = date.split('-');
    const showingTimeArr = showing.time.split(' ');
    const timeArr = showingTimeArr[0].split(':');

    if (showingTimeArr[1].toLowerCase() === 'pm') {
      timeArr[0] = Number(timeArr[0]) + 12;
    }

    const finalDateObj = new Date(
      Number(dateArr[2]),
      Number(dateArr[0]) - 1,
      Number(dateArr[1]),
      Number(timeArr[0]),
      Number(timeArr[1]),
      0,
    );

    await firebase.update(`outings/${outing}/info`, {
      date: theater.date || finalDateObj.toISOString(),
      theater: theater.title,
    });

    await firebase.update(`outings/${outing}/detail`, {
      address: `${theater.address.street} ${theater.address.city}, ${
        theater.address.state_abbr || theater.address.state
      } ${theater.address.zip}`,
      city: theater.address.city,
      latitude: theater.latitude,
      longitude: theater.longitude,
      theater: theater.title,
      ...(showing.tickets
        ? {
            links: [
              { type: 'Tickets', url: showing.tickets[0] },
              { type: 'Tickets', url: showing.tickets[1] },
            ],
          }
        : null),
    });

    dispatch({ type: 'CLOSE_SELECT_MOVIE_TIME' });

    await firebase.push(`outings/${outing}/messages`, {
      timestamp: new Date().toISOString(),
      type: 'selectShowtime',
      user,
    });

    scrollBottom(200);
  };

  render() {
    const { detail, info } = this.props;
    if (isEmpty(info) && isEmpty(detail)) {
      return '';
    }

    return (
      <div>
        {info.partner === 'goldenarm' && (
          <h1
            style={{
              borderBottom: '1px solid #f1f1f1',
              boxSizing: 'border-box',
              color: 'black',
              fontSize: '18px',
              fontWeight: '500',
              margin: '0 20px',
              padding: '0.67em 0',
              textAlign: 'center',
            }}
          >
            SXSW Showtimes: Choose Yours
          </h1>
        )}
        <MovieTimes detail={detail} info={info} type="select" exit={this.exit} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  detail: outingDetailSelector(state, props),
  info: outingInfoSelector(state, props),
  outing: outingIdSelector(state),
  user: userIdSelector(state, props),
});

export default compose(withFirebase, connect(mapStateToProps))(SelectMovieTimeComponent);
