import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';

import FirebasePhoneAuth from '../Widget/FirebasePhoneAuth';
import InviteComplete from './InviteComplete';
import { outingDetailSelector, outingInfoSelector } from '../../selectors/outings';

import '../Widget/event.css';

Modal.setAppElement('#root');

const customStyle = {
  content: {
    margin: '0 auto',
    maxHeight: '600px',
    maxWidth: '378px',
    padding: '0',
  },
};

class InviteFlowModal extends Component {
  constructor() {
    super();
    this.state = {
      page: 'inviteAuth',
    };
  }

  setPage = (page, event) => {
    const newState = {
      page,
    };
    if (event) {
      newState.event = event;
    }
    this.setState(newState);
  };

  handleClose = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'CLOSE_INVITE_FLOW' });
  };

  render() {
    const { detail, info, isOpen } = this.props;
    const { page } = this.state;

    const details = {
      address: detail.address,
      date: info.date,
      image: info.venueImage,
      performers: [{ name: info.title }],
      thumbnail: info.venueImage,
      venue: { name: detail.venueName },
    };

    return (
      <Modal isOpen={isOpen} style={customStyle} onRequestClose={this.handleClose}>
        <div className="widget-container">
          <div className="details">
            <div className="details-body">
              {page === 'inviteAuth' && (
                <FirebasePhoneAuth details={details} nextPage={() => this.setPage('redirect')} isInvite />
              )}
              {page === 'redirect' && <InviteComplete details={details} />}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ UIControl, ...state }, props) => ({
  detail: outingDetailSelector(state, props),
  info: outingInfoSelector(state, props),
  isOpen: UIControl.isInviteFlowOpen,
});

export default connect(mapStateToProps)(InviteFlowModal);
