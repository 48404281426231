import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';

import inviteReducer from './InviteReducer';
import UIControlReducer from './UIControlReducer';

const selectedOuting = (state = '', action) => {
  switch (action.type) {
    case 'SELECT_OUTING':
      return action.outing;
    case 'DESELECT_OUTING':
      return '';
    case 'SET_INVITE':
      return (action.data && action.data.outingId) || '';
    default:
      return state;
  }
};

// Add firebase to reducers
const rootReducer = combineReducers({
  UIControl: UIControlReducer,
  firebase: firebaseReducer,
  invite: inviteReducer,
  selectedOuting,
});

export default rootReducer;
