import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, withFirebase } from 'react-redux-firebase';

import {
  outingDetailSelector,
  outingInfoSelector,
  outingSuggestionsSelector,
  outingIdSelector,
} from '../../selectors/outings';
import { userSettingsSelector, userIdSelector } from '../../selectors/users';
import { addSuggestionToOuting } from '../../services/whosup';
import { scrollBottom } from '../../utils/scrolls';
import tracker from '../../utils/tracker';

import './Suggestions.css';
import './VenuesList.css';

class SuggestMovieTimesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMeetUpKey: '',
      showConfirmModal: false,
    };
  }

  exit = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'CLOSE_MEET_UP_SUGGESTIONS' });
  };

  validateVote = (suggestionId) => {
    const { user, suggestions } = this.props;
    const { upvotes } = suggestions[suggestionId];
    if (!upvotes) {
      return true;
    }

    const results = Object.keys(upvotes).filter((id) => id === user);
    if (results.length > 0) {
      return false;
    }

    return true;
  };

  voteById = (suggestionId) => {
    const { firebase, outing, suggestions, user } = this.props;
    firebase.update(`outings/${outing}/suggestions/${suggestionId}/upvotes/${user}`, new Date().toISOString());

    const suggestion = suggestions[suggestionId];
    tracker.track('Upvote Meet Up', {
      MeetUpSpot: suggestion.updates.venueName,
      Suggestion: suggestionId,
    });
  };

  unvoteById = (suggestionId) => {
    const { firebase, outing, user } = this.props;
    firebase.remove(`outings/${outing}/suggestions/${suggestionId}/upvotes/${user}`);
  };

  handleVote = (data) => {
    const { suggestionId } = data;
    if (!this.validateVote(suggestionId)) {
      this.unvoteById(suggestionId);
    } else {
      this.voteById(suggestionId);
    }
  };

  addToOuting = async (suggestionId) => {
    const { firebase, outing, suggestions } = this.props;
    const {
      updates: { location, venueName },
    } = suggestions[suggestionId];
    const userToken = await firebase.auth().currentUser.getIdToken(true);

    await addSuggestionToOuting({
      outingId: outing,
      selectedSuggestion: suggestionId,
      userToken,
    });

    tracker.track('Add Meet Up', {
      MeetUp: venueName,
    });

    this.exit();
    return scrollBottom(200);
  };

  render() {
    const { detail, firebase, info, settings, suggestions } = this.props;
    const { showConfirmModal, selectedMeetUpKey } = this.state;
    if (isEmpty(info) && isEmpty(detail) && isEmpty(suggestions)) {
      return '';
    }

    let suggArray = [];
    if (suggestions) {
      suggArray = Object.entries(suggestions).filter(([key, suggestion]) => suggestion.type === 'meetup');
    }

    const suggestionsFragment = suggArray.map(([key, suggestion]) => {
      return (
        <div key={key} className="suggestion-container">
          <div className="suggestion-container-info">
            <div className="suggestion-meetup-container">
              <div className="venue-item-category-pic">
                <img src={suggestion.updates.venueImage} alt="category pic" />
              </div>
              <div className="venue-item-details">
                {suggestion.updates.venueName}
                <br />
                <span className="venue-item-details-address">
                  {suggestion.updates.location.address}
                  <br />
                  {`${suggestion.updates.location.city} ${suggestion.updates.location.state}, ${suggestion.updates.location.zip}`}
                </span>
                <span>
                  <a
                    href={suggestion.data.moreInfo}
                    className="suggestion-add-to-outing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    More Info
                  </a>
                </span>
                <span
                  className="suggestion-add-to-outing"
                  onClick={() => this.setState({ selectedMeetUpKey: key, showConfirmModal: true })}
                >
                  Add to Outing
                </span>
              </div>
            </div>
          </div>
          <div
            className={`suggestion-container-button ${
              this.validateVote(key) ? '' : 'suggestion-container-button-active'
            }`}
          >
            {this.validateVote(key) && <div className="upvote-triangle" />}
            <button onClick={() => this.handleVote({ suggestionId: key })} type="button">
              {suggestion.upvotes && Object.keys(suggestion.upvotes).length > 0
                ? Object.keys(suggestion.upvotes).length
                : '0'}
            </button>
          </div>
        </div>
      );
    });

    return (
      <div className="suggestions-container">
        <h1>Meet Up Suggestions</h1>
        {suggArray.length && suggestionsFragment}
        {showConfirmModal && (
          <div className="suggestions-modal">
            <div className="suggestions-modal-text">Are you sure you want to add this as the Meet Up?</div>
            <div className="suggestions-modal-button" onClick={() => this.addToOuting(selectedMeetUpKey)}>
              Confirm
            </div>
            <div className="suggestions-modal-button" onClick={() => this.setState({ showConfirmModal: false })}>
              Cancel
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  detail: outingDetailSelector(state, props),
  info: outingInfoSelector(state, props),
  outing: outingIdSelector(state),
  settings: userSettingsSelector(state),
  suggestions: outingSuggestionsSelector(state, props),
  user: userIdSelector(state),
});

export default compose(withFirebase, connect(mapStateToProps))(SuggestMovieTimesComponent);
