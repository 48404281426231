import React from 'react';

import './OutOfApp.css';

import InviteFlowModal from './InviteFlowModal';
import Outing from '../Outing/Outing';

const OutOfAppComponent = ({ isInvite = false, trackFirstClick }) => {
  const trackClick = trackFirstClick || (() => {});

  return (
    <div className="base-container">
      <div className="app-container">
        <Outing trackFirstClick={trackClick} />
        {isInvite && <InviteFlowModal />}
      </div>
    </div>
  );
};

export default OutOfAppComponent;
