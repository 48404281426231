import React, { Component, Fragment } from 'react';
import { withFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { outingIdSelector, outingMemberStatusSelector } from '../../selectors/outings';
import { userNameSelector, userIdSelector } from '../../selectors/users';
import tracker from '../../utils/tracker';
import { fanticUrl } from '../../utils/fanticUrl';

import './OutingDetails.css';

class OutingActionsComponent extends Component {
  leaveOuting = () => {
    const confirmed = window.confirm('Are you sure you want to leave this outing?'); //eslint-disable-line
    if (confirmed) {
      const { firebase, outing, user } = this.props;
      firebase.remove(`outings/${outing}/info/members/${user}`);
      tracker.track('Leave Outing');
      window.location.href = fanticUrl('/#!/');
    }
  };

  logOut = async () => {
    const confirmed = window.confirm('Are you sure you want to log out?'); //eslint-disable-line
    if (confirmed) {
      const { firebase } = this.props;
      tracker.track('Log Out');
      await firebase.auth().signOut();
      window.location.href = fanticUrl('/#!/');
    }
  };

  toggleOpt = () => {
    const { firebase, outing, user, userStatus } = this.props;
    const newStatus = userStatus === 'accepted' ? 'declined' : 'accepted';
    firebase.ref(`outings/${outing}/info/members/${user}/status`).set(newStatus);
    tracker.track('Opt', {
      NewStatus: newStatus,
      OldStatus: userStatus,
    });
  };

  render() {
    const { userName, userStatus } = this.props;

    return (
      <Fragment>
        <div className="oed">
          <div className="detail-row">
            <div className="detail-row-content">
              <div className={`detail-link opt-${userStatus === 'accepted' ? 'out' : 'in'}`} onClick={this.toggleOpt}>
                Opt
                {userStatus === 'accepted' ? ' Out and stop SMS' : ' In'}
              </div>
            </div>
            <div className="detail-row-border" />
          </div>
          {/* <div className="detail-row">
            <div className="detail-row-content">
              <div className="detail-link leave" onClick={this.leaveOuting}>
                Leave Outing: Remove me and stop SMS
              </div>
            </div>
          </div> */}
        </div>
        <div className="oed">
          <div className="detail-row">
            <div className="detail-row-content">
              <div className="detail-link leave" onClick={this.logOut}>
                Log Out as {userName}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  outing: outingIdSelector(state),
  user: userIdSelector(state),
  userName: userNameSelector(state),
  userStatus: outingMemberStatusSelector(state, props),
});

export default compose(withFirebase, connect(mapStateToProps))(OutingActionsComponent);
