import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';

import Main from './Main';
import OutOfAppAuth from './OutOfApp/OutOfAppAuth';
import OutOfAppInvite from './OutOfApp/OutOfAppInvite';
import FetchEvent from './Widget/FetchEvent';
import Privacy from './Privacy';
import Terms from './Terms';

const AppComponent = () => (
  <Router hashType="hashbang">
    <Switch>
      <Route path="/privacy" component={Privacy} />
      <Route path="/terms" component={Terms} />
      <Route path="/upload/:event" component={FetchEvent} />
      <Route path="/event/:event" component={FetchEvent} />
      <Route path="/invite/:token" component={OutOfAppInvite} />
      <Route path="/:token" component={OutOfAppAuth} />
      <Route component={Main} />
    </Switch>
  </Router>
);

export default AppComponent;
