import React, { Component, Fragment } from 'react';
import { withFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { scrollTop } from '../../utils/scrolls';
import { uploadData } from '../../services/whosup';
import { userInfoSelector, userIdSelector } from '../../selectors/users';
import iconClose from '../../images/icon-close.svg';

import Methods from '../Auth/Methods';

import './UserProfile.css';
import UserAvatar from './UserAvatar';
import OutingActions from './OutingActions';

const ACCEPTED_AVATAR_FILETYPES = '.gif,.jpg,.jpeg,.png,.svg+xml,.webp';

class UserProfileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatarFileObject: null,
      bufferAvatar: null,
      editMode: false,
    };

    this.avatarUploadInput = React.createRef();
  }

  componentDidMount() {
    scrollTop(0);
  }

  render() {
    const { dispatch, userInfo } = this.props;
    const { bufferAvatar, editMode } = this.state;
    const { avatar, firstName, lastName } = userInfo;

    const closeProfile = () => {
      dispatch({ type: 'CLOSE_PROFILE' });
    };

    return (
      <div className="up">
        <div className="up-top-actions">
          <div className="up-close" onClick={closeProfile}>
            <img src={iconClose} alt="Close Details" />
          </div>
          <div className="up-edit" onClick={this.handleEditMode}>
            {editMode ? 'Save' : 'Edit'}
          </div>
        </div>
        <div className="up-avatar-container">
          <div className="up-avatar-wrapper" onClick={this.handleChangeAvatar}>
            <UserAvatar avatar={bufferAvatar || avatar} customStyle="up-avatar" />
          </div>
          {editMode && (
            <Fragment>
              <input
                type="file"
                ref={this.avatarUploadInput}
                accept={ACCEPTED_AVATAR_FILETYPES}
                style={{ display: 'none' }}
                onChange={this.handleFiles}
              />
              <div className="up-avatar-overlay" onClick={this.handleChangeAvatar}>
                Edit
              </div>
            </Fragment>
          )}
        </div>
        <div className="up-info">
          <div className="up-name">
            <div className="up-label">Name</div>
            {`${firstName} ${lastName}`}
          </div>
          <div className="up-methods">
            <div className="up-label">Linked Accounts</div>
            <Methods />
          </div>
        </div>
        <OutingActions />
      </div>
    );
  }

  handleFiles = () => {
    const avatarFileObject = this.avatarUploadInput.current.files[0];
    if (!avatarFileObject || !avatarFileObject.type.includes('image/')) {
      return;
    }

    const objectURL = window.URL.createObjectURL(avatarFileObject);
    this.setState({ avatarFileObject, bufferAvatar: objectURL });
  };

  handleChangeAvatar = () => {
    if (this.avatarUploadInput.current) {
      this.avatarUploadInput.current.click();
    }
  };

  handleEditMode = async () => {
    const { avatarFileObject, bufferAvatar, editMode } = this.state;
    if (editMode) {
      if (bufferAvatar && avatarFileObject) {
        await this.uploadAvatar();
      }
    }
    this.setState({ avatarFileObject: null, bufferAvatar: null, editMode: !editMode });
  };

  uploadAvatar = async () => {
    const { firebase, user } = this.props;
    const { avatarFileObject } = this.state;

    const reader = new FileReader();
    reader.onloadend = async () => {
      const finalFile = reader.result.split(',')[1];
      const URL = await uploadData({ payload: finalFile, type: 'avatar' });
      if (URL) {
        firebase.database().ref(`users/${user}/info/avatar`).set(URL);
      }
    };

    reader.readAsDataURL(avatarFileObject);
  };
}

const mapStateToProps = (state) => ({
  user: userIdSelector(state),
  userInfo: userInfoSelector(state),
});

export default compose(withFirebase, connect(mapStateToProps))(UserProfileComponent);
