import moment from 'moment';

const FB_APP_ID = '606252383208379';

export const generateLink = ({ inviteType, message, title, url }) => {
  switch (inviteType) {
    case 'sms':
      return `sms:?&body=${encodeURIComponent(message)}`;
    case 'messenger':
      return `fb-messenger://share/?link=${encodeURIComponent(url)}&app_id=${encodeURIComponent(FB_APP_ID)}`;
    case 'whatsapp':
      return `whatsapp://send?text=${encodeURIComponent(message)}`;
    case 'email':
      return `mailto:?subject=Who's up for... ${encodeURIComponent(title)}&body=${encodeURIComponent(message)}`;
    default:
      return url;
  }
};

export const generateMessage = ({ date, firstName, releaseDate, title, type, url, venueName }) => {
  let message = '';
  if (type === 'movie') {
    if (title === 'Golden Arm') {
      message += `${firstName} is inviting you to the Golden Arm premiere at SXSW in March!\n\n`;
      message += `Click 👇 to see showtimes and get the inside scoop!\n${url}`;
    } else {
      message += `${firstName} is inviting you to see ${title}, `;
      const d = moment(releaseDate || date);
      if (releaseDate && d.isAfter()) {
        message += `in theatres ${d.format('MMMM Do')}.\n\n`;
      } else {
        message += `now in theaters.\n\n`;
      }
      message += `Click this link 👇 and lets figure out a showtime\n${url}`;
    }
  } else if (type === 'concert') {
    // message += `${firstName} is inviting you to see ${title}, `;
    // message += `on ${moment(releaseDate || date).format('MMMM Do')} @ ${venueName}.\n\n`;
    // message += `Click this link 👇 so we can find some tickets and more...\n${url}`;
    message += `Join my private group for ${title} `;
    if (releaseDate || date) {
      message += `on ${moment(releaseDate || date).format('MMMM Do')}`;
    }
    if (venueName) {
      message += ` at ${venueName}`;
    }
    message += '.\n\nWe’ll get all the details and exclusives.\n\n';
    message += `Click this link 👇 to join:\n${url}`;
  } else {
    message += `Join my private group for ${title} `;
    if (releaseDate || date) {
      message += `on ${moment(releaseDate || date).format('MMMM Do')}`;
    }
    if (venueName) {
      message += ` at ${venueName}`;
    }
    message += '.\n\nWe’ll get all the details and exclusives.\n\n';
    message += `Click this link 👇 to join:\n${url}`;
    // message += `Who's up for...\n\n${title}\n${venueName}\n`;
    // message += `${moment(date).format('ddd, MMM D, YYYY')}\n\n`;
    // message += `Click to respond 👇\n${url}`;
  }

  return message;
};
