import React, { Component } from 'react';

import { search } from '../../services/whosup';

import './VenuesList.css';

class VenuesListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customQuery: '',
      venues: [],
    };
  }

  componentDidMount() {
    this.fetchVenues();
  }

  fetchVenues = async (query = '') => {
    const {
      detail: { latitude, longitude },
    } = this.props;
    const radius = query.length > 0 ? '10' : '5';

    let data = {};
    try {
      data = await search({ latitude, longitude, query, type: ['foursquare'], within: radius });
    } catch (error) {
      console.error(error.stack);
    }

    this.setState({ venues: data.results || [] });
  };

  handleVenueSearch = (e) => {
    clearTimeout(this.delayTimer);
    const customQuery = e.target.value;
    this.setState({ customQuery });
    this.delayTimer = setTimeout(() => {
      this.fetchVenues(customQuery);
    }, 700);
  };

  render() {
    const { closeTooltip, exit, meetupSuggestionCopy, showMeetupSuggestionTooltip } = this.props;
    const { venues, customQuery } = this.state;

    let selectMeetupTooltip;
    if (showMeetupSuggestionTooltip) {
      selectMeetupTooltip = (
        <div className="fullscreen-tooltip-container" onClick={closeTooltip}>
          <div className="fullscreen-tooltip">{meetupSuggestionCopy}</div>
        </div>
      );
    }

    return (
      <div className="oed">
        {selectMeetupTooltip}
        <div className="suggest-meet-up-search">
          <input
            type="text"
            value={customQuery}
            placeholder="Type to search for a place..."
            onChange={this.handleVenueSearch}
          />
        </div>
        {venues.map((venue) => (
          <div key={venue.id} className="venue-item-container" onClick={() => exit(venue)}>
            <div className="venue-item-category-pic">
              <img src={venue.thumbnail} alt="category pic" />
            </div>
            <div className="venue-item-details">
              {venue.title}
              <br />
              <span className="venue-item-details-address">
                {venue.address.street}
                <br />
                {`${venue.address.city}, ${venue.address.state} ${venue.address.zip}`}
              </span>
            </div>
            <div className="venue-item-distance">{venue.distance.toFixed(2)} mi</div>
          </div>
        ))}
      </div>
    );
  }
}

export default VenuesListComponent;
