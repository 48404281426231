import moment from 'moment';

import { search } from '../services/whosup';

export default async (id, provider = 'ticketmaster') => {
  const options = {
    type: [provider],
  };
  if (provider === 'ticketmaster') {
    options.size = 40;
    options.attractionId = id;
  } else if (provider === 'whosup') {
    options.query = id;
  }

  let data;
  try {
    data = await search(options);
  } catch (error) {
    console.error(error.stack);
  }

  if (!Array.isArray(data.results)) {
    return {
      results: [],
    };
  }

  let tourName = '';
  let tourImage = '';
  const tourNamesWeight = {};
  const tourNamesSorted = [];
  const tourImagesWeight = {};
  const tourImagesSorted = [];
  data.results.sort((a, b) => moment(a.date).diff(moment(b.date)));
  data.results.forEach((result) => {
    if (!tourNamesWeight[result.title]) {
      tourNamesWeight[result.title] = 0;
    }
    if (!tourImagesWeight[result.image]) {
      tourImagesWeight[result.image] = 0;
    }
    tourNamesWeight[result.title] += 1;
    tourImagesWeight[result.image] += 1;
  });
  Object.keys(tourNamesWeight).forEach((title) => {
    tourNamesSorted.push({ title, weight: tourNamesWeight[title] });
  });
  Object.keys(tourImagesWeight).forEach((image) => {
    tourImagesSorted.push({ image, weight: tourImagesWeight[image] });
  });
  tourNamesSorted.sort((a, b) => b.weight - a.weight);
  tourImagesSorted.sort((a, b) => b.weight - a.weight);
  if (tourNamesSorted.length) {
    tourName = tourNamesSorted[0].title;
  }
  if (tourImagesSorted.length) {
    tourImage = tourImagesSorted[0].image;
  }

  return {
    results: data.results.filter((result) => !!result.address),
    tourImage,
    tourName,
  };
};
