import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import './UserAvatar.css';

const UserAvatarComponent = ({ avatar, customStyle, height, width }) => {
  return (
    <div
      style={{ backgroundImage: `url(${avatar})`, height: `${height}px`, width: `${width}px` }}
      className={`avatar ${customStyle}`}
    />
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(UserAvatarComponent);
