import React, { Component } from 'react';

import CreateOuting from './CreateOuting';
import Details from './Details';
import EventUploadBody from './EventUploadBody';
import FirebasePhoneAuth from './FirebasePhoneAuth';
import GetStarted from './GetStarted';
import { TourDates } from './TourInfo';

import fetchTour from '../../utils/fetchTour';
import tracker from '../../utils/tracker';
import { isMobile } from '../../utils/userAgent';
import fanticlogo from '../../images/Event/fanticlogo.png';
import fanticpowered from '../../images/Event/powered.png';

import './event.css';

class EventPopup extends Component {
  constructor(props) {
    super(props);

    const { event } = this.props;

    this.state = {
      event,
    };
  }

  async componentDidMount() {
    const { event } = this.state;

    tracker.track('OOA Upload: Opens Upload Page', {
      ...event,
    });
  }

  componentDidUpdate(prevProps) {
    const { eventId } = this.props;
    const { eventId: oldEventId } = prevProps;
    if (eventId !== oldEventId) {
      this.updateEvent();
    }
  }

  updateEvent() {
    const { event } = this.props;
    this.setState({
      event,
    });
  }

  render() {
    const { event } = this.state;

    return (
      <div className="widget-container event">
        <div className="details">
          <div
            className="details-header-initial"
            style={{
              backgroundImage: `url(${event.header})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            {/* <a href="https://fantic.io" className="details-learn" rel="noopener noreferrer" target="_blank">
              Learn about Fantic
            </a> */}
          </div>
          {/* <div className="details-thumbnail-initial">
            <img className="thumbnail-logo" src={fanticlogo} alt="Fantic Powered" />
          </div> */}
          <EventUploadBody event={event} />
        </div>
      </div>
    );
  }
}

export default EventPopup;
