import React, { Component } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, withFirebase } from 'react-redux-firebase';

import {
  outingDetailSelector,
  outingInfoSelector,
  outingSuggestionsSelector,
  outingIdSelector,
} from '../../selectors/outings';
import { userSettingsSelector, userIdSelector } from '../../selectors/users';
import { addSuggestionToOuting } from '../../services/whosup';
import { scrollBottom } from '../../utils/scrolls';
import tracker from '../../utils/tracker';

import './Suggestions.css';

class ShowtimeSuggestionsComponent extends Component {
  exit = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'CLOSE_SHOWTIME_SUGGESTIONS' });
  };

  validateVote = (suggestionId) => {
    const { user, suggestions } = this.props;
    const { upvotes } = suggestions[suggestionId];
    if (!upvotes) {
      return true;
    }

    const results = Object.keys(upvotes).filter((id) => id === user);
    if (results.length > 0) {
      return false;
    }

    return true;
  };

  voteById = (suggestionId) => {
    const { firebase, outing, suggestions, user } = this.props;
    firebase.update(`outings/${outing}/suggestions/${suggestionId}/upvotes`, {
      [user]: new Date().toISOString(),
    });

    const suggestion = suggestions[suggestionId];
    tracker.track('Upvote Showtimes', {
      Date: new Date(suggestion.updates.date).toISOString(),
      Suggestion: suggestionId,
      Theater: suggestion.updates.venueName,
    });
  };

  unvoteById = (suggestionId) => {
    const { firebase, outing, user } = this.props;
    firebase.remove(`outings/${outing}/suggestions/${suggestionId}/upvotes/${user}`);
  };

  handleVote = (data) => {
    const { suggestionId } = data;
    if (!this.validateVote(suggestionId)) {
      this.unvoteById(suggestionId);
    } else {
      this.voteById(suggestionId);
    }
  };

  addToOuting = async (suggestionId) => {
    const { firebase, outing, suggestions } = this.props;
    const {
      updates: { date, venueName },
    } = suggestions[suggestionId];
    const userToken = await firebase.auth().currentUser.getIdToken(true);

    let isoDate = moment(date, moment.ISO_8601, true);
    if (!isoDate.isValid()) {
      isoDate = moment(date, 'MM-DD-YYYY h:mm A');
    }

    await addSuggestionToOuting({
      outingId: outing,
      selectedSuggestion: suggestionId,
      userToken,
    });

    tracker.track('Add Showtime', {
      Date: isoDate.toISOString(),
      Theater: venueName,
    });

    this.exit();
    return scrollBottom(200);
  };

  render() {
    const { detail, firebase, info, settings, suggestions, user } = this.props;
    if (isEmpty(info) && isEmpty(detail) && isEmpty(suggestions)) {
      return '';
    }
    let suggArray = [];
    if (suggestions) {
      suggArray = Object.entries(suggestions).filter(([key, suggestion]) => suggestion.type === 'showtime');
    }

    const showShowtimeSuggestionsTooltip = !(settings && settings.seenShowtimeSuggestionsTooltip);

    let selectShowtimesTooltip;
    if (showShowtimeSuggestionsTooltip) {
      selectShowtimesTooltip = (
        <div
          className="fullscreen-tooltip-container"
          onClick={() => firebase.database().ref(`users/${user}/settings/seenShowtimeSuggestionsTooltip`).set(true)}
        >
          <div className="fullscreen-tooltip">Tap to upvote the showtimes that work for you.</div>
        </div>
      );
    }

    const suggestionsFragment = suggArray.map(([key, suggestion]) => {
      let date = moment(suggestion.updates.date, moment.ISO_8601, true);
      if (!date.isValid()) {
        date = moment(suggestion.updates.date, 'MM-DD-YYYY h:mm A');
      }
      suggestions[key].updates.date = date.toISOString();

      return (
        <div key={key} className="suggestion-container">
          {selectShowtimesTooltip}
          <div className="suggestion-container-info">
            <h1>{suggestion.updates.venueName}</h1>
            <h2>{date.format('ddd, MMM Do @ h:mm A')}</h2>

            <span className="suggestion-add-to-outing" onClick={() => this.addToOuting(key)}>
              Select this Showtime
            </span>
          </div>
          <div
            className={`suggestion-container-button ${
              this.validateVote(key) ? '' : 'suggestion-container-button-active'
            }`}
          >
            {this.validateVote(key) && <div className="upvote-triangle" />}
            <button onClick={() => this.handleVote({ suggestionId: key })} type="button">
              {suggestion.upvotes && Object.keys(suggestion.upvotes).length > 0
                ? Object.keys(suggestion.upvotes).length
                : '0'}
            </button>
          </div>
        </div>
      );
    });

    return (
      <div className="suggestions-container">
        <h1>Showtime Suggestions</h1>
        {suggArray.length && suggestionsFragment}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  detail: outingDetailSelector(state, props),
  info: outingInfoSelector(state, props),
  outing: outingIdSelector(state),
  settings: userSettingsSelector(state, props),
  suggestions: outingSuggestionsSelector(state, props),
  user: userIdSelector(state),
});

export default compose(withFirebase, connect(mapStateToProps))(ShowtimeSuggestionsComponent);
