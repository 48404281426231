import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { compose } from 'redux';

import SendActions from './SendActions';
import PlusIcon from '../../images/plus-icon.png';
import PromoIcon from '../../images/logo.png';

import { outingCustomCopySelector, outingIdSelector } from '../../selectors/outings';
import { userSettingsSelector, userIdSelector } from '../../selectors/users';
import { scrollBottom } from '../../utils/scrolls';
import tracker from '../../utils/tracker';
import { isiOs } from '../../utils/userAgent';

import './Send.css';

class SendComponent extends Component {
  state = {
    inputMessage: '',
    isActionsOpen: false,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  handleKeyPress = (event) => {
    switch (event.key) {
      case 'Enter':
        this.sendMessage();
        break;
      default:
    }
  };

  handleInputChange = (event) => {
    const { target } = event;
    this.setState({ inputMessage: target.value });
  };

  sendMessage = async () => {
    const { firebase, user, outing } = this.props;
    const { inputMessage } = this.state;

    if (inputMessage.length > 0) {
      await firebase.push(`outings/${outing}/messages`, {
        body: inputMessage,
        timestamp: new Date().toISOString(),
        type: 'standard',
        user,
      });

      this.inputRef.current.blur();

      tracker.increment({
        'Sent Messages': 1,
      });
      tracker.track('Message Sent');
      this.setState({ inputMessage: '' });
      scrollBottom(200);
    }
  };

  closeSendActions = () => {
    this.setState({ isActionsOpen: false });
  };

  render() {
    const { customCopy, firebase, isInvite, user, settings } = this.props;
    const { inputMessage, isActionsOpen } = this.state;
    const { tooltips } = customCopy || {};
    const showOutingTooltips = !(settings && settings.seenOutingTooltips);
    const showPromo = false && isiOs && settings && settings.seenOutingTooltips && !settings.seenPromo;

    return (
      <Fragment>
        {!isInvite && (
          <Fragment>
            <div className="send-white-bg" />
            <div className="send">
              {/* <div className="send-border" /> */}
              <div className="send-top">
                {showOutingTooltips && (
                  <div className="outing-tooltip">
                    {(tooltips && tooltips.sendAction) || 'Invite Friends and Suggest a Meet Up'}
                  </div>
                )}
                <div className="send-actions">
                  <div
                    className="open-send-actions"
                    onClick={(e) => {
                      e.preventDefault();
                      if (isActionsOpen) {
                        this.setState({ isActionsOpen: false });
                        tracker.track('Plus (+) icon');
                      } else {
                        this.setState({ isActionsOpen: true });
                      }
                    }}
                  >
                    <div className="open-send-actions-icon">
                      <img className="open-send-actions-plus-sign" src={PlusIcon} alt="open actions" />
                    </div>
                  </div>
                </div>
                <div className="send-input-div">
                  <input
                    ref={this.inputRef}
                    className="send-input"
                    type="text"
                    placeholder="Write a message"
                    name="messageInput"
                    onKeyPress={this.handleKeyPress}
                    value={inputMessage}
                    onChange={this.handleInputChange}
                  />
                </div>

                <div
                  className={`send-button ${inputMessage.length > 0 ? 'send-ready' : ''}`}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={this.sendMessage}
                >
                  Send
                </div>
              </div>
              {isActionsOpen && (
                <div className="send-actions-popup">
                  <SendActions close={this.closeSendActions} />
                </div>
              )}
              {showPromo && (
                <div className="outing-promo">
                  <img className="outing-promo-icon" src={PromoIcon} alt="promo-icon" />
                  <div className="outing-promo-title">The best experience is on the app.</div>
                  Use it for concerts, movies, and more.
                  <a
                    href="https://apps.apple.com/us/app/whosup-social-planning/id1105298110"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="outing-promo-button"
                  >
                    Get the App
                  </a>
                  <div
                    className="outing-promo-close-button"
                    onClick={() => firebase.database().ref(`users/${user}/settings/seenPromo`).set(true)}
                  >
                    x
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  customCopy: outingCustomCopySelector(state, props),
  isInvite: state.invite.isInvite,
  outing: outingIdSelector(state),
  settings: userSettingsSelector(state),
  user: userIdSelector(state),
});

export const SendInvite = connect(mapStateToProps)(SendComponent);

export default compose(withFirebase, connect(mapStateToProps))(SendComponent);
