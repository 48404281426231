export const fanticUrl = (pathOrUrl) => {
  let path = pathOrUrl;
  try {
    const url = new URL(pathOrUrl);
    if (url.hostname === 'app.fantic.io') {
      const [_, type, slug] = url.pathname.split('/');
      if (type === 'ooa') {
        path = `/#!/${slug}`;
      } else {
        path = `/#!/invite/${slug}`;
      }
    } else if (url.hostname === 'www.fantic.io') {
      return pathOrUrl;
    } else {
      path = url.pathname + url.hash;
    }
  } catch (error) {
    // ignore
  }

  if (process.env.REACT_APP_RUN_TYPE === 'local') {
    return `http://localhost:3000${path}`;
  }

  return `https://fantic.io${path}`;
};

export default fanticUrl;
