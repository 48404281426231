import React, { Component, Fragment } from 'react';
import { withFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { scrollTop } from '../../utils/scrolls';
import { userIdSelector } from '../../selectors/users';
import {
  outingHostSelector,
  outingLivestreamLinksSelector,
  outingWatchPartyLinkSelector,
} from '../../selectors/outings';
import LiveStreamLinks from './LiveStreamLinks';

import './LiveStreamInfo.css';
import WatchPartyButton from './WatchPartyButton';

const LiveStreamInfoComponent = ({ host, livestreamLinks, userId, watchPartyLink }) => {
  const hasLinks = !!livestreamLinks.length;
  const isHost = userId === host;
  const isWatchEnabled = !!watchPartyLink;
  let hostFragment;
  let pageFragment = <LiveStreamLinks livestreamLinks={livestreamLinks} />;

  if (hasLinks) {
    if (isWatchEnabled) {
      pageFragment = (
        <>
          <div className="lsl-links-container">
            <div className="lsl-links-header">Watch with friends is enabled!</div>
            <div className="lsl-link-container">
              {isWatchEnabled && (
                <a href={watchPartyLink} target="_blank" rel="noopener noreferrer" className="lsl-link">
                  Join the Watch Party!
                </a>
              )}
            </div>
          </div>
          <LiveStreamLinks livestreamLinks={livestreamLinks} />
        </>
      );
    } else {
      pageFragment = <LiveStreamLinks livestreamLinks={livestreamLinks} />;
    }
  } else {
    pageFragment = (
      <div className="lsl-links-container">
        <div className="lsl-links-header">Your Live Stream Access will be posted here 2 hours before the show.</div>
      </div>
    );
  }

  if (isHost) {
    if (isWatchEnabled && !hasLinks) {
      pageFragment = (
        <div className="lsl-links-container">
          <div className="lsl-links-header">
            You’re all set! Your Watch with Friends and Live Stream Access will be posted here 2 hours before the show.
          </div>
        </div>
      );
    } else if (!isWatchEnabled) {
      hostFragment = (
        <>
          <div className="lsi-enable-watch">
            <div className="lsi-enable-left">
              <div className="lsi-enable-text">Enable Watch with Friends for your Outing:</div>
            </div>
            <div className="lsi-enable-right">
              <WatchPartyButton />
            </div>
          </div>
          <div className="lsl-links-container">
            <div className="lsl-links-header">
              Fantic offers &quot;Watch with Friends&quot; through Google Meet, enable it above.
            </div>
          </div>
        </>
      );
    }
  }

  return (
    <div className="lsi">
      {hostFragment}
      {pageFragment}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  host: outingHostSelector(state, props),
  livestreamLinks: outingLivestreamLinksSelector(state, props),
  userId: userIdSelector(state),
  watchPartyLink: outingWatchPartyLinkSelector(state),
});

export default compose(withFirebase, connect(mapStateToProps))(LiveStreamInfoComponent);
