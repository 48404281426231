import { createSelector } from 'reselect';
import { userIdSelector } from './users';

export const outingIdSelector = (state, props) => state.selectedOuting || props.outingId || props.outing;
export const outingsSelector = (state) =>
  (state.invite.isInvite && state.invite.data.outings) || state.firebase.data.outings || {};

export const outingSelector = createSelector(
  outingsSelector,
  outingIdSelector,
  (outings, outingId) => outings && outingId && outings[outingId],
);

export const outingDetailSelector = createSelector(outingSelector, (outing) => outing && outing.detail);

export const outingInfoSelector = createSelector(outingSelector, (outing) => outing && outing.info);

export const outingMessagesSelector = createSelector(
  outingSelector,
  (outing) => outing && outing.messages && Object.entries(outing.messages).map(([key, value]) => ({ key, value })),
);

export const outingSuggestionsSelector = createSelector(outingSelector, (outing) => outing && outing.suggestions);

export const outingCustomCopySelector = createSelector(
  outingInfoSelector,
  (outingInfo) => (outingInfo && outingInfo.customCopy) || {},
);

export const outingHostSelector = createSelector(
  outingInfoSelector,
  (outingInfo) => (outingInfo && outingInfo.host) || {},
);

export const outingDateSelector = createSelector(outingInfoSelector, (outingInfo) => outingInfo && outingInfo.date);

export const outingIsLivestreamSelector = createSelector(
  outingInfoSelector,
  (outingInfo) => outingInfo && outingInfo.subType === 'livestream',
);

export const outingHasShowtimesSelector = createSelector(
  outingInfoSelector,
  (outingInfo) => outingInfo && outingInfo.hasShowtimes,
);

export const outingLinksSelector = createSelector(
  outingDetailSelector,
  (outingDetail) => outingDetail && outingDetail.links,
);

export const outingMeetupSelector = createSelector(
  outingDetailSelector,
  (outingDetail) => outingDetail && outingDetail.meetup,
);

export const outingMemberSelector = createSelector(
  userIdSelector,
  outingInfoSelector,
  (user, outingInfo) => outingInfo && outingInfo.members && outingInfo.members[user],
);

export const outingMembersSelector = createSelector(
  outingInfoSelector,
  (outingInfo) =>
    outingInfo && outingInfo.members && Object.entries(outingInfo.members).map(([key, value]) => ({ key, value })),
);

export const outingMemberStatusSelector = createSelector(
  outingMemberSelector,
  (outingMember) => outingMember && outingMember.status,
);

export const outingPartnerSelector = createSelector(
  outingInfoSelector,
  (outingInfo) => outingInfo && outingInfo.partner,
);

export const outingTypeSelector = createSelector(outingInfoSelector, (outingInfo) => outingInfo && outingInfo.type);

export const outingWatchPartyLinkSelector = createSelector(outingInfoSelector, ({ watchParty }) => watchParty);

export const outingLivestreamLinksSelector = createSelector(outingLinksSelector, (links) => {
  if (Array.isArray(links) && links.length) {
    return links.filter((link) => link.type === 'livestream');
  }
  return [];
});
