import React from 'react';

export default ({ latitude, longitude }) => (
  <div>
    {latitude !== 0 && longitude !== 0 ? (
      <iframe
        title="gmaps"
        width="100%"
        height="300"
        id="gmap_canvas"
        src={`https://maps.google.com/maps?q=${latitude}%2C%20${longitude}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
      />
    ) : (
      <div className="detail-no-maps">No location selected yet</div>
    )}
  </div>
);
