import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { compose } from 'redux';

import Loading from './Loading';
import OutOfApp from './OutOfApp';
import { fetchOutingShare, joinOutingShare } from '../../services/whosup';
import tracker from '../../utils/tracker';
import { userIdSelector } from '../../selectors/users';
import { fanticUrl } from '../../utils/fanticUrl';

class OutOfAppInvite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outingId: null,
    };
  }

  async componentDidMount() {
    const {
      match: {
        params: { token },
      },
      setInvite,
    } = this.props;

    let data;
    try {
      data = await fetchOutingShare(token);
    } catch (error) {
      console.error(error.stack);
      return;
    }

    if (data.status === 'success') {
      const { firebase, userId } = this.props;
      const { info } = data.outings[data.outingId];
      const { creationDate, date, host, members, title } = info;
      const { currentUser } = firebase.auth();
      const { displayName, metadata } = currentUser || {};
      const { creationTime } = metadata || {};
      const user = data.users[userId] || {};
      const userInfo = user.info || {};

      if (userId && members[userId]) {
        try {
          const userToken = await currentUser.getIdToken(true);
          const url = await joinOutingShare({
            outingShare: token,
            userToken,
          });

          tracker.track('OOA Invite: Redirect to OOA Recipient');
          window.location = fanticUrl(url);
          // this.setState({ text: "You're All Set!", url: data.url })
          return;
        } catch (error) {
          console.error(error.stack);
        }
      }

      setInvite(data, token);
      this.setState({ outingId: data.outingId });

      tracker.register({
        'Date Outing Created': creationDate || 'None',
        'Date of Outing': date || 'None',
        'Invite Messaging Channel': 'None',
        'Number of Outing Members': Object.keys(members).length,
        'Outing Creation Type': 'OOA Invite',
        'Outing Creator Name': host || 'None',
        'Outing Location': (data.detail && data.detail.venueName) || 'None',
        'Outing Name': title || 'None',
      });
      tracker.people({
        $created: creationTime,
        $first_name: userInfo.firstName,
        $last_login: new Date(),
        $last_name: userInfo.lastName,
        'Account Create Date': creationTime,
        Device: navigator.userAgent,
        'Last Open Date': new Date(),
        'User ID': userId,
      });
      tracker.union({
        'Outings Visited': data.outingId,
      });
      tracker.track('OOA Invite: Opens Invite');
    }
  }

  render() {
    const { outingId } = this.state;
    if (outingId === null) {
      return <Loading />;
    }

    return <OutOfApp isInvite />;
  }
}

const mapDispatchToProps = {
  setInvite: (data, token) => ({ data, token, type: 'SET_INVITE' }),
};

const mapStateToProps = (state, props) => ({
  userId: userIdSelector(state, props),
});

export default compose(withFirebase, connect(mapStateToProps, mapDispatchToProps))(OutOfAppInvite);
