import { combineReducers } from 'redux';

const data = (state = {}, action) => {
  switch (action.type) {
    case 'SET_INVITE':
      return action.data;
    case 'UNSET_INVITE':
      return {};
    default:
      return state;
  }
};

const isInvite = (state = false, action) => {
  switch (action.type) {
    case 'SET_INVITE':
      return true;
    case 'UNSET_INVITE':
      return false;
    default:
      return state;
  }
};

const token = (state = '', action) => {
  switch (action.type) {
    case 'SET_INVITE':
      return action.token;
    case 'UNSET_INVITE':
      return '';
    default:
      return state;
  }
};

const inviteReducer = combineReducers({
  data,
  isInvite,
  token,
});

export default inviteReducer;
