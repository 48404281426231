import React from 'react';

import TourStop from './TourStop';

import './TourInfo.css';

export const TourDates = ({ nextPage, results }) => (
  <div className="tour-dates">
    <div className="tour-results">
      {results.length === 0 && <div className="tour-loading">Loading...</div>}
      {results.map((result) => (
        <div key={result.id}>
          <TourStop
            address={result.address}
            artist={result.performers[0]}
            date={result.date}
            thumbnail={result.thumbnail}
            venue={result.venue}
            title={result.title}
            onClick={() => nextPage(result)}
          />
        </div>
      ))}
    </div>
  </div>
);

// Todo: eventType needs to show different types of "events" based on generic or main
export const TourInfoComponent = ({ nextPage, results, tourImage, tourName, eventType }) => (
  <div className="tour-info">
    <div className="tour-header" style={{ backgroundImage: `url(${tourImage})` }}>
      <div className="tour-name">{tourName || 'Loading...'}</div>
    </div>
    <TourDates nextPage={nextPage} results={results} />
  </div>
);

export default TourInfoComponent;
