import React, { Component } from 'react';

import CreateOuting from './CreateOuting';
import Details from './Details';
import Event from './Event';
import FirebasePhoneAuth from './FirebasePhoneAuth';
import GetStarted from './GetStarted';
import { TourDates } from './TourInfo';

import fetchTour from '../../utils/fetchTour';
import tracker from '../../utils/tracker';
import { isMobile } from '../../utils/userAgent';
import fanticlogo from '../../images/Event/fanticlogo.png';
import fanticpowered from '../../images/Event/powered.png';

import './event.css';

const normalizeEvent = (event) => ({
  address: {},
  date: null,
  id: event.id,
  image: event.header,
  links: [],
  location: {},
  performers: event.detail.performers,
  thumbnail: event.thumbnail,
  tickets: [],
  title: event.title,
  type: 'whosup',
  // venue: {},
});

class EventPopup extends Component {
  constructor(props) {
    super(props);

    const { event } = this.props;

    this.state = {
      event,
      inviteType: 'skip',
      page: event.type === 'tour' ? 'tourDates' : 'getStarted',
      results: [],
      tourName: '',
    };
  }

  async componentDidMount() {
    const { event } = this.state;
    const tourId = event.tourId || event.attractionId;

    if (tourId) {
      const { results, tourImage, tourName } = await fetchTour(tourId, event.tourProvider);
      this.setState({
        results,
        tourImage,
        tourName,
      });
    }

    tracker.track('OOA Create: Opens Promo', {
      ...event,
    });
  }

  componentDidUpdate(prevProps) {
    const { eventId } = this.props;
    const { eventId: oldEventId } = prevProps;
    if (eventId !== oldEventId) {
      this.updateEvent();
    }
  }

  setPage = (page, event) => {
    const newState = {
      page,
    };
    const { event: oldEvent } = this.state;
    if (event) {
      let newEvent = event;
      if (oldEvent.tourProvider === 'whosup') {
        newEvent = event.original;
      }
      newState.event = Object.assign({}, oldEvent, newEvent);
    }
    this.setState(newState);

    switch (page) {
      case 'outingDetails':
        tracker.track('OOA Create: Get Started');
        break;
      default:
        break;
    }
  };

  setInviteType = (inviteType) => {
    this.setState({ inviteType });

    tracker.register({
      'Invite Messaging Channel': inviteType,
    });
    tracker.track('OOA Create: Select Invite Channel', {
      channel: inviteType,
    });
  };

  updateEvent() {
    const { event } = this.props;
    this.setState({
      event,
    });
  }

  render() {
    const { event, page, inviteType, results, tourImage, tourName } = this.state;

    let details = {};
    if (event.type === 'concert' && event.detail) {
      details = {
        date: event.date,
        performers: event.detail.performers,
        venue: {
          name: event.detail.venueName,
        },
      };
    }

    return (
      <div className="widget-container event">
        <div className="details">
          <div
            className={`details-header-initial ${page !== 'getStarted' ? 'details-header' : ''}`}
            style={{
              backgroundImage: `url(${event.header})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            {/* <a href="https://fantic.io" className="details-learn" rel="noopener noreferrer" target="_blank">
              Learn about Fantic
            </a> */}
          </div>
          {/* <div className={`details-thumbnail-initial ${page !== 'getStarted' ? 'details-thumbnail' : ''}`}>
            <img className="thumbnail-logo" src={fanticlogo} alt="Fantic Powered" />
          </div> */}
          <div className="details-body">
            {page === 'tourDates' && (
              <div className="details-body">
                <h1 className="tour-copy">
                  Choose the group you want to create, and invite your family, friends, or co-workers to join.
                </h1>
              </div>
            )}

            {page === 'getStarted' && (
              <GetStarted
                customCopy={(event.customCopy && event.customCopy.getStarted) || {}}
                nextPage={() => {
                  let nextPage = 'firebaseAuth';
                  if (!isMobile) {
                    nextPage = 'firebaseAuth';
                  }
                  if (event.type === 'tour') {
                    nextPage = 'tourDates';
                  }
                  this.setPage(nextPage);
                }}
                setInviteType={this.setInviteType}
              />
            )}
            {/* TODO: switch from isMobile to !isMobile */}
            {page === 'tourDates' && (
              <div>
                <TourDates
                  nextPage={(ev) => this.setPage('firebaseAuth', ev)}
                  results={[normalizeEvent(event)]}
                  tourImage={tourImage}
                  tourName={tourName}
                />
                <h1 className="event-type" style={{ marginTop: '40px' }}>
                  Specific Group Chats (Optional)
                </h1>
                <TourDates
                  nextPage={(ev) => this.setPage('firebaseAuth', ev)}
                  results={results}
                  tourImage={tourImage}
                  tourName={tourName}
                />
              </div>
            )}
            {page === 'firebaseAuth' && (
              <FirebasePhoneAuth details={event} nextPage={() => this.setPage('createOuting')} />
            )}
            {page === 'createOuting' && <CreateOuting details={event} inviteType={inviteType} />}
          </div>
        </div>
      </div>
    );
  }
}

export default EventPopup;
