import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';

import {
  outingDetailSelector,
  outingInfoSelector,
  outingMembersSelector,
  outingSuggestionsSelector,
} from '../../selectors/outings';
import { usersSelector } from '../../selectors/users';
import tracker from '../../utils/tracker';
import UserAvatar from './UserAvatar';

import './OutingMembers.css';

const STATUS = {
  GOING: 'accepted',
  NOT_GOING: 'pending',
};

const EVENT_TYPE = {
  CONCERT: 'concert',
  MOVIE: 'movie',
  RESTAURANT: 'restaurant',
};

const OutingMembersComponent = ({ detail, dispatch, info, members, suggestions, users }) => {
  if (isEmpty(members) || isEmpty(users) || isEmpty(info) || isEmpty(detail)) {
    return null;
  }

  const addFriends = () => {
    tracker.track('Add User');
    dispatch({ type: 'OPEN_ADD_FLOW' });
  };

  const trackTicketClick = (ev) => {
    const { target } = ev;
    tracker.increment({
      'Clicked Tickets': 1,
    });
    tracker.track('Buy Tickets', {
      Copy: target.innerHTML,
      Url: target.href,
    });
  };

  let hasShowtimeSuggestions = false;
  let hasMeetupSuggestions = false;
  Object.keys(suggestions || {}).forEach((suggestionId) => {
    const suggestion = suggestions[suggestionId];
    if (suggestion.type === 'showtime') {
      hasShowtimeSuggestions = true;
    }
    if (suggestion.type === 'meetup') {
      hasMeetupSuggestions = true;
    }
  });

  return (
    <div className="om">
      <div className="detail-row">
        <div className="detail-row-content om-amount">{members.length} People Total</div>
        <div className="detail-add-friends" onClick={addFriends}>
          + Invite Friends
        </div>
      </div>

      {members.map((member) => (
        <Fragment key={member.key}>
          {users[member.key] && users[member.key].info && (
            <div className="detail-row">
              <div className="om-row-content">
                <UserAvatar
                  width={30}
                  height={30}
                  customStyle="om-avatar"
                  avatar={users[member.key].info.avatar}
                  alt="om-avatar"
                />
                {`${users[member.key].info.firstName} ${users[member.key].info.lastName || ''}`}
                {member.value.status === STATUS.GOING && <span className="om-going-text">is going</span>}
              </div>
              <div className="detail-row-border" />
            </div>
          )}
        </Fragment>
      ))}

      {/* <div>See all members</div> */}

      {/* {info.type === EVENT_TYPE.RESTAURANT && (
        <a className="detail-row contextual-actions" href={detail.moreInfo}>
          More Info about {info.title}
        </a>
      )} */}

      {/* {info.type === EVENT_TYPE.CONCERT &&
        info.subType !== 'livestream' &&
        detail.links &&
        detail.links.map((link) => (
          <a key={link.url} className="detail-row contextual-actions" href={link.url}>
            {link.description}
          </a>
        ))} */}
      {/* {info.type === EVENT_TYPE.CONCERT && info.subType === 'livestream' && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className="detail-row contextual-actions"
          onClick={() => {
            dispatch({ type: 'OPEN_LIVESTREAM_INFO' });
          }}
          type="button"
        >
          Live Stream Links
        </a>
      )}
      {info.type === EVENT_TYPE.MOVIE && detail.links && (
        <a
          key={detail.links[0].url}
          className="detail-row contextual-actions"
          href={detail.links[0].url}
          target="_blank"
          onClick={trackTicketClick}
          rel="noopener noreferrer"
        >
          Buy tickets from Fandango
        </a>
      )}
      {info.partner === 'goldenarm' && (
        <div
          className="detail-row contextual-actions"
          onClick={(e) => {
            e.preventDefault();
            dispatch({ type: 'OPEN_SELECT_MOVIE_TIME' });
          }}
        >
          View Showtimes
        </div>
      )}
      {hasShowtimeSuggestions && (
        <div
          className="detail-row contextual-actions"
          onClick={(e) => {
            e.preventDefault();
            dispatch({ type: 'OPEN_SHOWTIME_SUGGESTIONS' });
          }}
        >
          Showtime Suggestions
        </div>
      )}
      {hasMeetupSuggestions && (
        <div
          className="detail-row contextual-actions"
          onClick={(e) => {
            e.preventDefault();
            dispatch({ type: 'OPEN_MEET_UP_SUGGESTIONS' });
          }}
        >
          Meet Up Suggestions
        </div>
      )} */}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  detail: outingDetailSelector(state, props),
  info: outingInfoSelector(state, props),
  members: outingMembersSelector(state, props),
  suggestions: outingSuggestionsSelector(state, props),
  users: usersSelector(state),
});

export default connect(mapStateToProps)(OutingMembersComponent);
