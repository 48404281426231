import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import { compose } from 'redux';

import MessageContent, { MessageContentInvite } from './MessageContent';
import {
  outingDetailSelector,
  outingMeetupSelector,
  outingMessagesSelector,
  outingSuggestionsSelector,
} from '../../selectors/outings';
import './Conversation.css';

class ConversationComponent extends Component {
  shouldComponentUpdate(nextProps) {
    const { isCoverOpen } = this.props;
    const { isCoverOpen: nextIsCoverOpen } = nextProps;

    if (nextIsCoverOpen !== isCoverOpen) {
      return false;
    }
    return true;
  }

  shouldGenerateDateBang = (index, timestamp) => {
    const { messages } = this.props;
    if (index + 1 === messages.length) {
      return false;
    }

    const current = moment(timestamp).format('L');
    const next = moment(messages[index + 1].value.timestamp).format('L');

    if (current !== next) {
      return true;
    }

    return false;
  };

  openDetails = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'OPEN_DETAILS' });
  };

  render() {
    const { isInvite, messages, meetup, detail, dispatch, suggestions } = this.props;
    if (isEmpty(messages)) {
      return '';
    }

    let hasMeetupSuggestions = false;
    Object.keys(suggestions || {}).forEach((suggestionId) => {
      const suggestion = suggestions[suggestionId];
      if (suggestion.type === 'meetup') {
        hasMeetupSuggestions = true;
      }
    });

    return (
      <div className="conversation">
        <div className="pills-container">
          <div className="pills">
            <div className="pill" onClick={() => dispatch({ type: 'OPEN_GROUP_LIST' })}>
              Group List
            </div>
            {Array.isArray(detail.links) &&
              detail.links.map(({ description, type, url }) => (
                <a key={url} className="pill" href={url} target="_blank" rel="noopener noreferrer">
                  {description}
                </a>
              ))}
            {detail.latitude && detail.longitude && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="pill"
                href={`https://m.uber.com/ul/?action=setPickup&dropoff[latitude]=${detail.latitude}&dropoff[longitude]=${detail.longitude}`}
              >
                Rideshare
              </a>
            )}
            {hasMeetupSuggestions && (
              <div
                className="pill"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({ type: 'OPEN_MEET_UP_SUGGESTIONS' });
                }}
              >
                Meet Up
              </div>
            )}
            {/* <div className="pill">Split Pay</div> */}
          </div>
        </div>
        {meetup && (
          <a href={meetup.moreInfo} className="meet-up-container" target="_blank" rel="noopener noreferrer">
            <strong>Meet Up Spot:</strong> {meetup.venueName}
            <div className="meet-up-arrow-container">
              <div className="meet-up-arrow" />
            </div>
          </a>
        )}
        {messages.map(({ key, value }, index) => (
          <div className="message-container" key={key}>
            {index === 0 && (
              <div className="bang-date-container initial">
                {/* <div className="bang-line" /> */}
                <div className="bang-date">{moment(value.timestamp).format('dddd, MMMM D')}</div>
              </div>
            )}

            {!isInvite ? <MessageContent content={value} /> : <MessageContentInvite content={value} />}

            {this.shouldGenerateDateBang(index, value.timestamp) && (
              <div className="bang-date-container">
                {/* <div className="bang-line" /> */}
                <div className="bang-date">{moment(messages[index + 1].value.timestamp).format('dddd, MMMM D')}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ UIControl, ...state }, props) => ({
  detail: outingDetailSelector(state, props),
  isCoverOpen: UIControl.isCoverOpen,
  isInvite: state.invite.isInvite,
  meetup: outingMeetupSelector(state, props),
  messages: outingMessagesSelector(state, props),
  suggestions: outingSuggestionsSelector(state, props),
});

export default connect(mapStateToProps)(ConversationComponent);
