import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import EventPopUp from './EventPopUp';
import EventUpload from './EventUpload';
import Loading from '../OutOfApp/Loading';
import { fetchEvent } from '../../services/whosup';
import tracker from '../../utils/tracker';

class FetchEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: false,
      notFound: false,
    };
  }

  componentDidMount() {
    this.updateEvent();
  }

  componentDidUpdate(prevProps) {
    const { eventId, match } = this.props;
    const { eventId: oldeventId, match: oldMatch } = prevProps;
    const { params } = match || {};
    const { params: oldParams } = oldMatch || {};
    const { event } = params || {};
    const { event: oldEvent } = oldParams || {};

    if (event !== oldEvent || eventId !== oldeventId) {
      this.updateEvent();
    }
  }

  async updateEvent() {
    const { eventId, match } = this.props;
    const { params } = match || {};
    const { event } = params || {};
    this.setState({
      data: null,
    });

    try {
      const data = await fetchEvent(eventId || event);
      this.setState({
        data,
        notFound: false,
      });
      tracker.register({
        'Date Outing Created': 'Not yet created',
        'Date of Outing': data.date || 'None',
        'Invite Messaging Channel': 'None',
        'Number of Outing Members': 0,
        'Outing Creation Type': 'Event Page',
        'Outing Creator Name': 'Not yet created',
        'Outing Location': data.location || 'None',
        'Outing Name': data.title || 'None',
      });
    } catch (error) {
      console.error(error.stack);
      this.setState({
        notFound: true,
      });
    }
  }

  render() {
    const { match } = this.props;
    const { data, notFound } = this.state;

    if (data) {
      if (match.path === '/upload/:event') {
        if (data.type !== 'tour') {
          return <EventUpload eventId={data.id} event={data} />;
        }
        return <Redirect to={`/event/${match.params.event}`} />;
      }
      return <EventPopUp eventId={data.id} event={data} />;
    }
    if (notFound) {
      return <Redirect to="/" />;
    }
    return <Loading />;
  }
}

export default FetchEvent;
