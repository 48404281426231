import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { compose } from 'redux';

import { joinOutingShare } from '../../services/whosup';
import tracker from '../../utils/tracker';
import './InviteComplete.css';
import '../Widget/Details.css';
import { fanticUrl } from '../../utils/fanticUrl';

class InviteComplete extends Component {
  /* constructor(props) {
    super(props);
    this.state = {
      text: 'Please wait...',
      url: null,
    };
  }
  */

  async componentDidMount() {
    const { firebase, token } = this.props;
    const { currentUser } = firebase.auth();

    let url;
    try {
      const userToken = await currentUser.getIdToken(true);
      url = await joinOutingShare({
        outingShare: token,
        userToken,
      });
      window.location.reload();
    } catch (error) {
      console.error(error.stack);
      return;
    }

    const {
      displayName,
      metadata: { creationTime },
      phoneNumber,
    } = currentUser;
    const userInfo = (await firebase.database().ref(`users/${displayName}/info`).once('value')).val();
    tracker.people({
      $created: creationTime,
      $first_name: userInfo.firstName,
      $last_login: new Date(),
      $last_name: userInfo.lastName,
      $phone: phoneNumber,
      'Account Create Date': creationTime,
      Device: navigator.userAgent,
      'Last Open Date': new Date(),
      'User ID': displayName,
    });
    tracker.increment({
      'Outings Joined': 1,
    });
    tracker.track('OOA Invite: Joins Outing');
    window.location = fanticUrl(url);
    // this.setState({ text: "You're All Set!", url: data.url })
  }

  render() {
    // const { text, url } = this.state;
    // const { details } = this.props;
    return (
      <div>
        {/*
      <div className="details signup">
        <div className="details-header" style={{ backgroundImage: `url(${details.image})` }}>
          <div className="details-title">
            Your Outing
          </div>
        </div>
        <Event details={details} show={['artist', 'date', 'subtitle', 'title', 'venue']} />
        <div className="InviteCompleteContainer">
          {text && <div className="InviteCompleteText">{text}</div>}
          {url && (
            <a className="InviteCompleteButton" href={url}>
              Go to this Outing
            </a>
          )}
        </div>
      </div>
      */}
      </div>
    );
  }
}

export default compose(
  withFirebase,
  connect((state) => ({
    token: state.invite.token,
  })),
)(InviteComplete);
