import Clipboard from 'clipboard';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { compose } from 'redux';

import Details from './Details';
import InviteCreated from './InviteCreated';
import { outingSelector, outingIdSelector } from '../../selectors/outings';
import { userInfoSelector } from '../../selectors/users';
import { createOutingShare } from '../../services/whosup';
import { generateLink, generateMessage } from '../../utils/outingShare';
import tracker from '../../utils/tracker';
import { isFacebook, isMobile } from '../../utils/userAgent';
import { fanticUrl } from '../../utils/fanticUrl';

import './CreateOuting.css';

let copy;

class AddFriendsPopUp extends Component {
  state = {
    currentAction: 'Creating your Fantic user',
    inviteType: 'sms',
    // page: isMobile ? 'outingDetails' : 'createInvite',
    page: 'outingDetails',
    redirectLink: '',
  };

  componentDidMount() {
    copy = new Clipboard('.copy');
    copy.on('success', () => {
      this.setAction('Link Copied to your Clipboard');
      setTimeout(() => {
        this.setAction('');
      }, 3000);
    });
    // if (!isMobile) {
    //   this.createInvite();
    // }
    this.generateUrl();
  }

  componentWillUnmount() {
    copy.destroy();
  }

  setAction = (currentAction) => {
    this.setState({
      currentAction,
    });
  };

  setInviteType = (inviteType) => {
    this.setState({ inviteType });
  };

  generateUrl = async () => {
    const { firebase, outingId } = this.props;
    const userToken = await firebase.auth().currentUser.getIdToken(true);
    const url = await createOutingShare({
      outingId,
      userToken,
    });
    this.setState({
      url,
    });
  };

  createInvite = async () => {
    const { firebase, outingId } = this.props;
    this.setState({
      page: 'createInvite',
    });
    this.setAction('Creating your private group');

    try {
      const { url } = this.state;
      this.handleRedirect(url);
    } catch (error) {
      console.error(error);
    }
  };

  handleRedirect = (url) => {
    const {
      firstName,
      outing: {
        detail: { venueName },
        info: { date, releaseDate, title, type },
      },
    } = this.props;
    const { inviteType } = this.state;

    const message = generateMessage({
      date,
      firstName,
      releaseDate,
      title,
      type,
      url,
      venueName,
    });
    const redirectLink = generateLink({
      inviteType,
      message,
      title,
      url,
    });

    this.setAction(``);
    this.setState({
      message,
      redirectLink,
      url,
    });

    tracker.track('OOA: Invite Friends Link Generated');

    if (isMobile && !(isFacebook && inviteType === 'messenger')) {
      const newWindow = window.open(redirectLink, '_blank');
      if (!newWindow) {
        window.location.href = fanticUrl(redirectLink);
      }
    }
  };

  render() {
    const { currentAction, inviteType, message, page, redirectLink, url } = this.state;
    return (
      <div className="widget-container addFriends" style={{ backgroundColor: '#fff' }}>
        <div className="details">
          <div className="details-body" style={{ margin: '0 auto', maxWidth: '450px' }}>
            {page === 'outingDetails' && (
              <Details nextPage={() => this.createInvite()} setInviteType={this.setInviteType} link={url} />
            )}
            {page === 'createInvite' && (
              <InviteCreated
                currentAction={currentAction}
                inviteType={inviteType}
                message={message}
                redirectLink={redirectLink}
                url={url}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  firstName: userInfoSelector(state, props).firstName,
  outing: outingSelector(state, props),
  outingId: outingIdSelector(state),
});

export default compose(withFirebase, connect(mapStateToProps))(AddFriendsPopUp);
