import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import OutingEventDetails from './OutingEventDetails';
import OutingMembers from './OutingMembers';
import AddFriendsPopUp from '../Widget/AddFriendsPopUp';
import { scrollTop } from '../../utils/scrolls';

import './OutingDetails.css';
import OutingActions from './OutingActions';

const OutingDetailsComponent = ({ isAddFlowOpen, isInvite, isGroupListOpen, isLocationOpen }) => {
  scrollTop(0);

  return (
    <div className="od">
      {isInvite && (
        <Fragment>
          {isGroupListOpen && <OutingMembers />}
          {isLocationOpen && <OutingEventDetails />}
        </Fragment>
      )}
      {!isInvite && isAddFlowOpen && <AddFriendsPopUp />}
      {!isInvite && !isAddFlowOpen && (
        <Fragment>
          {isGroupListOpen && <OutingMembers />}
          {isLocationOpen && <OutingEventDetails />}
          {/* <OutingActions /> */}
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAddFlowOpen: state.UIControl.isAddFlowOpen,
  isGroupListOpen: state.UIControl.isGroupListOpen,
  isInvite: state.invite.isInvite,
  isLocationOpen: state.UIControl.isLocationOpen,
});

export default connect(mapStateToProps)(OutingDetailsComponent);
