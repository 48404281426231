import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/app';

import './Outing.css';

import OutingDetails from './OutingDetails';
import SelectMovieTime from './SelectMovieTime';
import ShowtimeSuggestions from './ShowtimeSuggestions';
import SuggestMeetUp from './SuggestMeetUp';
import SuggestMovieTimes from './SuggestMovieTimes';
import TopBar, { TopBarInvite } from './TopBar';
import UserProfile from './UserProfile';
import GetStarted from '../Widget/GetStarted';
import Conversation from '../Conversation/Conversation';
import Send, { SendInvite } from '../Conversation/Send';
import tracker from '../../utils/tracker';
import MeetUpSuggestions from './MeetUpSuggestions';
import LiveStreamInfo from './LiveStreamInfo';
import Details from '../Widget/Details';
import { generateLink, generateMessage } from '../../utils/outingShare';

import { outingDetailSelector, outingInfoSelector, outingIdSelector } from '../../selectors/outings';
import { createOutingShare } from '../../services/whosup';
import { isFacebook, isMobile } from '../../utils/userAgent';

let hasClicked = false;

const OutingComponent = ({
  dispatch,
  isInvite,
  isInviteFriendsOverlayOpen,
  isProfileOpen,
  isSuggestMeetUpOpen,
  isSuggestMovieTimesOpen,
  isSelectMovieTimeOpen,
  isShowtimeSuggestionsOpen,
  isMeetUpSuggestionsOpen,
  isLiveStreamInfoOpen,
  trackFirstClick,
  detail,
  setAction,
  outingInfo,
  outingId,
  isDetailsOpen,
}) => {
  const [page, setPage] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (!isInvite) {
      setPage('firebaseAuth');
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((userToken) => {
          return createOutingShare({
            outingId,
            userToken,
          });
        })
        .then((newUrl) => {
          setUrl(newUrl);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRedirect = useCallback(
    (inviteType) => {
      const message = generateMessage({
        date: outingInfo.date,
        firstName: outingInfo.firstName,
        releaseDate: outingInfo.releaseDate,
        title: outingInfo.title,
        type: outingInfo.type,
        url,
        venueName: outingInfo.venueName,
      });

      const redirectLink = generateLink({
        inviteType,
        message,
        title: outingInfo.title,
        url,
      });

      tracker.track('OOA: Invite Friends Link Generated');

      const newWindow = window.open(redirectLink, '_blank');
      if (!newWindow) {
        window.location.href = redirectLink;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url],
  );

  const shouldHideConvo =
    isDetailsOpen ||
    isProfileOpen ||
    isSuggestMeetUpOpen ||
    isSelectMovieTimeOpen ||
    isSuggestMovieTimesOpen ||
    isShowtimeSuggestionsOpen ||
    isLiveStreamInfoOpen ||
    isMeetUpSuggestionsOpen;
  return (
    <div className="outing-container">
      <div className="cover-container">
        {!isInvite && !isProfileOpen && <TopBar />}
        {isInvite && <TopBarInvite />}
      </div>
      <div
        className="content-container"
        onClick={() => {
          if (typeof trackFirstClick === 'function' && !isInvite && !hasClicked) {
            hasClicked = true;
            trackFirstClick();
          }
        }}
      >
        {isSuggestMeetUpOpen && (
          <div className="suggest-meet-up-container">
            <SuggestMeetUp />
          </div>
        )}
        {isDetailsOpen && (
          <div className="outing-details-container">
            <OutingDetails />
          </div>
        )}
        {isProfileOpen && (
          <div className="outing-details-container">
            <UserProfile />
          </div>
        )}
        {isSuggestMovieTimesOpen && (
          <div className="movie-times-container">
            <SuggestMovieTimes />
          </div>
        )}
        {isShowtimeSuggestionsOpen && (
          <div className="movie-times-container">
            <ShowtimeSuggestions />
          </div>
        )}
        {isMeetUpSuggestionsOpen && (
          <div className="movie-times-container">
            <MeetUpSuggestions />
          </div>
        )}
        {isSelectMovieTimeOpen && (
          <div className="movie-times-container">
            <SelectMovieTime />
          </div>
        )}
        {isLiveStreamInfoOpen && (
          <div className="movie-times-container">
            <LiveStreamInfo />
          </div>
        )}
        {!shouldHideConvo && (
          <Fragment>
            <div className="conversation-container">
              <Conversation />
              <div className="send-container">{!isInvite ? <Send /> : <SendInvite />}</div>
            </div>
          </Fragment>
        )}
        {isInviteFriendsOverlayOpen && !isInvite && (
          <Details
            details={detail}
            nextPage={(inviteType) => handleRedirect(inviteType)}
            link={url}
            closeModal={() => dispatch({ type: 'CLOSE_INVITE_FRIENDS_OVERLAY' })}
          />
        )}
        {isInvite && (
          <GetStarted
            isInvite
            nextPage={() => {
              tracker.track('OOA Invite: Opts In');
              dispatch({ type: 'OPEN_INVITE_FLOW' });
            }}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ UIControl, ...state }, props) => ({
  ...UIControl,
  detail: outingDetailSelector(state, props),
  isInvite: state.invite.isInvite,
  outingId: outingIdSelector(state, props),
  outingInfo: outingInfoSelector(state, props),
});

export default connect(mapStateToProps)(OutingComponent);
