import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, withFirebase } from 'react-redux-firebase';
import moment from 'moment-timezone';

import AddToCalIcon from '../../images/iconAddToCalendar.png';
import iconClose from '../../images/icon-close.svg';
import FanticLogo from '../../images/fantic-logo.svg';
import location from '../../images/Event/location.png';
import ButtonArrow from '../../images/icon-arrow-right-white.svg';
import {
  outingDetailSelector,
  outingInfoSelector,
  outingIsLivestreamSelector,
  outingLivestreamLinksSelector,
  outingWatchPartyLinkSelector,
} from '../../selectors/outings';
import { userSettingsSelector, userIdSelector, userAvatarSelector } from '../../selectors/users';
import { bodyOrHtml } from '../../utils/scrolls';
import tracker from '../../utils/tracker';

import './TopBar.css';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPastCover: false,
    };
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].onscroll = this.handleScroll;
  }

  componentDidUpdate() {
    const { detail, info } = this.props;

    if (detail && info) {
      tracker.register({
        'Date Outing Created': info.creationDate || 'None',
        'Date of Outing': info.date || 'None',
        'Invite Messaging Channel': 'None',
        'Number of Outing Members': Object.keys(info.members).length,
        'Outing Creation Type': 'OOA',
        'Outing Creator Name': info.host || 'None',
        'Outing Location': detail.venueName || 'None',
        'Outing Name': info.title || 'None',
      });
    }
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].onscroll = () => {};
  }

  handleAddToCal = () => {
    tracker.track('Add to Calendar');
  };

  handleCheck = () => {
    const {
      dispatch,
      firebase,
      user,
      outing,
      info: { members },
      isInvite,
    } = this.props;

    if (isInvite) {
      dispatch({ type: 'OPEN_INVITE_FLOW' });
    } else if (members[user].status === 'accepted') {
      firebase.set(`outings/${outing}/info/members/${user}/status`, 'pending');
    } else {
      firebase.set(`outings/${outing}/info/members/${user}/status`, 'accepted');
    }
  };

  returnHome = () => {
    const { dispatch, isAddFlowOpen } = this.props;

    if (isAddFlowOpen) {
      dispatch({ type: 'CLOSE_ADD_FLOW' });
    } else {
      dispatch({ type: 'OPEN_COVER' });
    }
  };

  openDetails = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'OPEN_DETAILS' });
    tracker.track('Settings Access');
  };

  openProfile = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'OPEN_PROFILE' });
    tracker.track('Profile Access');
  };

  handleScroll = () => {
    const { isPastCover } = this.state;
    const scrollPosition = bodyOrHtml().scrollTop;
    if (scrollPosition > 375) {
      if (isPastCover) {
        return;
      }
      this.setState({ isPastCover: true });
      return;
    }
    if (!isPastCover) {
      return;
    }
    this.setState({ isPastCover: false });
  };

  renderAvatarButton = () => {
    const { isInvite, isLivestream, userAvatar } = this.props;

    if (isInvite || isLivestream) {
      return null;
    }

    return (
      <div className="avatar-container">
        <div style={{ backgroundImage: `url(${userAvatar})` }} className="avatar" onClick={this.openProfile} />
      </div>
    );
  };

  renderDetailsButton = () => (
    <div className="topbar-details-button" onClick={this.openDetails}>
      <div className="topbar-details-shade" />
      <div className="topbar-details-dots">...</div>
    </div>
  );

  render() {
    const { detail, info } = this.props;
    if (isEmpty(info) || isEmpty(detail)) {
      return '';
    }

    const {
      info: { date, hasShowtimes, partner, strictTimeZone, title, theater, venueImage },
      detail: { address, externalService, latitude, longitude, venueName },
      dispatch,
      isCoverOpen,
      isDetailsOpen,
      isInvite,
      isLivestream,
      isLiveStreamInfoOpen,
      isInviteFriendsOverlayOpen,
      isMeetUpSuggestionsOpen,
      isSelectMovieTimeOpen,
      isShowtimeSuggestionsOpen,
      isSuggestMeetUpOpen,
      isSuggestMovieTimesOpen,
      livestreamLinks,
      outing,
      settings,
      watchPartyLink,
    } = this.props;
    const hasLivestreamLink = !!livestreamLinks.length;
    const venue = theater || venueName;
    const { isPastCover } = this.state;
    const formattedTitle = (!isCoverOpen || isPastCover) && title.length > 20 ? `${title.substring(0, 20)}...` : title;

    const coverImageStyle = {
      background: `url(${venueImage})`,
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };

    const showOutingTooltips = !isInvite && !(settings && settings.seenOutingTooltips);

    let time;
    if (date) {
      if (hasShowtimes === false) {
        time = `In Theatres ${moment(date).format('MMMM D, YYYY')}`;
      } else if (date.indexOf(':01.') !== -1) {
        time = moment(date).format('dddd MMMM D, YYYY');
      } else {
        time = moment(date).format('ddd, MMM D • h:mm A');
      }
      if (strictTimeZone) {
        const momentDate = moment(date);
        const momentGuessedZone = moment.tz.guess();
        const strictOffset = moment.tz.zone(strictTimeZone).utcOffset(momentDate.unix());
        if (momentGuessedZone) {
          const momentGuessedOffset = moment.tz.zone(momentGuessedZone).utcOffset(momentDate.unix());
          if (momentGuessedOffset !== strictOffset) {
            time = momentDate.tz(strictTimeZone).format('ddd, MMM D • h:mm A z');
          }
        }
      }
    } else if (hasShowtimes === true) {
      time = (
        <div
          className="select-showtime-copy"
          onClick={() => {
            dispatch({ type: 'OPEN_SELECT_MOVIE_TIME' });
            tracker.track(`${date ? 'Edit' : 'Add'} Showtimes`, { Action: 'Open' });
          }}
        >
          Select your showtime!
        </div>
      );
    } else if (externalService === 'tour') {
      time = '';
    } else {
      // time = 'TBD';
      time = '';
    }

    return (
      <div className={`topbar ${isCoverOpen ? 'topbar-open' : ''}`}>
        {!isDetailsOpen &&
          !isSuggestMeetUpOpen &&
          !isSelectMovieTimeOpen &&
          !isShowtimeSuggestionsOpen &&
          !isSuggestMovieTimesOpen &&
          !isLiveStreamInfoOpen &&
          !isMeetUpSuggestionsOpen && (
            <div className={`topbar-cover-container ${!isCoverOpen ? 'topbar-bar' : ''}`}>
              <div style={coverImageStyle} className={`cover-image ${!isCoverOpen && 'cover-image-collapsed'}`} />

              <div className={`cover-shade ${isCoverOpen ? 'cover-shade-hide' : ''}`} />

              {isCoverOpen && <div className="cover-detail-gradient" />}
            </div>
          )}
        <div className="cover-detail">
          {!isSuggestMeetUpOpen && (
            <Fragment>
              <div className={`cover-detail-title ${!isCoverOpen && 'cover-detail-title-collapsed'}`}>
                {formattedTitle}
              </div>
              {isCoverOpen && partner === 'goldenarm' && <div className="cover-detail-subtitle">SXSW Premiere</div>}
              <div className={`cover-detail-date ${!isCoverOpen && 'cover-detail-date-collapsed'}`}>{time}</div>
              {isCoverOpen && venue && (
                <div className="cover-detail-theater" onClick={() => dispatch({ type: 'OPEN_LOCATION' })}>
                  <img className="cover-detail-theater-locationPin" src={location}></img>
                  {venue}
                </div>
              )}
              {isLivestream &&
                !isLiveStreamInfoOpen &&
                !isDetailsOpen &&
                !isSuggestMeetUpOpen &&
                !isSelectMovieTimeOpen &&
                !isShowtimeSuggestionsOpen &&
                !isSuggestMovieTimesOpen &&
                !isLiveStreamInfoOpen &&
                !isMeetUpSuggestionsOpen && (
                  <div className="cover-detail-livestream">
                    <div
                      className="cover-detail-livestream-button"
                      onClick={() => {
                        if (watchPartyLink && livestreamLinks.length) {
                          window.open(watchPartyLink, '_blank');
                        } else {
                          dispatch({ type: 'OPEN_LIVESTREAM_INFO' });
                        }
                      }}
                    >
                      <div className="cover-detail-livestream-button-text">
                        {watchPartyLink && livestreamLinks.length ? 'Watch with Friends' : 'Live Stream Info'}
                      </div>
                      <img src={ButtonArrow} alt="right-arrow" />
                    </div>
                    {watchPartyLink && !!livestreamLinks.length && (
                      <div
                        className="cover-detail-livestream-google-meet"
                        onClick={() => {
                          dispatch({ type: 'OPEN_LIVESTREAM_INFO' });
                        }}
                      >
                        Live Stream Info
                      </div>
                    )}
                  </div>
                )}
            </Fragment>
          )}
        </div>

        <div className="topbar-title">{isSuggestMeetUpOpen && 'Suggest Meet Up Spot'}</div>

        {(isDetailsOpen ||
          isSuggestMeetUpOpen ||
          isSelectMovieTimeOpen ||
          isSuggestMovieTimesOpen ||
          isShowtimeSuggestionsOpen ||
          isLiveStreamInfoOpen ||
          isMeetUpSuggestionsOpen) && (
          <div className="topbar-back-button" onClick={this.returnHome}>
            <img src={iconClose} alt="Close Details" />
          </div>
        )}

        {isDetailsOpen && latitude && longitude && (
          <div className="topbar-add-to-cal-button" onClick={this.handleAddToCal}>
            <a
              href={`https://api.fantic.io/outing/${encodeURIComponent(outing)}/ical.ics?date=${encodeURIComponent(
                date,
              )}&location=${encodeURIComponent(address)}&subject=${encodeURIComponent(title)}${
                strictTimeZone ? `&timezone=${encodeURIComponent(strictTimeZone)}` : ''
              }`}
            >
              <img src={AddToCalIcon} alt="add to calendar icon" />
            </a>
          </div>
        )}

        {!isDetailsOpen &&
          !isSuggestMeetUpOpen &&
          !isSelectMovieTimeOpen &&
          !isShowtimeSuggestionsOpen &&
          !isSuggestMovieTimesOpen &&
          !isLiveStreamInfoOpen &&
          !isMeetUpSuggestionsOpen &&
          !isInviteFriendsOverlayOpen && (
            <Fragment>
              {/* <div className="fantic-logo">
                <img src={FanticLogo} alt="add to calendar icon" />
              </div> */}
              {this.renderAvatarButton()}
              {/* {!isInvite && !isInviteFriendsOverlayOpen && this.renderDetailsButton()} */}
              {showOutingTooltips && <div className="open-details outing-tooltip">Get Tickets and more!</div>}
            </Fragment>
          )}

        {!isDetailsOpen &&
          !isSuggestMeetUpOpen &&
          !isLiveStreamInfoOpen &&
          !isSelectMovieTimeOpen &&
          !isSuggestMovieTimesOpen &&
          !isShowtimeSuggestionsOpen &&
          !isMeetUpSuggestionsOpen &&
          isPastCover && (
            <div className="topbar-bar">
              <div style={coverImageStyle} className="cover-image cover-image-collapsed" />
              <div className="cover-shade" />
              <div className="cover-detail">
                <div className="cover-detail-title cover-detail-title-collapsed">{formattedTitle}</div>
                <div className="cover-detail-date cover-detail-date-collapsed">{time}</div>
              </div>
              {this.renderAvatarButton()}
              {/* {this.renderDetailsButton()} */}
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  ...state.UIControl,
  detail: outingDetailSelector(state, props),
  info: outingInfoSelector(state, props),
  isInvite: state.invite.isInvite,
  isLivestream: outingIsLivestreamSelector(state),
  livestreamLinks: outingLivestreamLinksSelector(state, props),
  settings: userSettingsSelector(state),
  user: userIdSelector(state),
  userAvatar: userAvatarSelector(state),
  watchPartyLink: outingWatchPartyLinkSelector(state),
});

export const TopBarInvite = connect(mapStateToProps)(TopBar);

export default compose(withFirebase, connect(mapStateToProps))(TopBar);
