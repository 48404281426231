import React, { useCallback, useEffect, useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { withFirebase } from 'react-redux-firebase';
import { updateOauth } from '../../../services/whosup';

const GoogleProvider = ({ firebase }) => {
  const [isGoogleAuthed, setIsGoogleAuthed] = useState(false);

  const handleGoogleResponse = useCallback(
    async ({ code }) => {
      try {
        const userToken = await firebase.auth().currentUser.getIdToken(true);
        const { id_token } = await updateOauth({ code, provider: 'google.com', userToken });

        const credential = firebase.auth.GoogleAuthProvider.credential(id_token);
        await firebase.auth().currentUser.linkWithCredential(credential);
        setIsGoogleAuthed(true);
      } catch (error) {
        console.error(error);
      }
    },
    [firebase],
  );

  useEffect(() => {
    const { currentUser } = firebase.auth();
    const googleDetails = currentUser.providerData.filter(({ providerId }) => providerId === 'google.com');
    if (googleDetails.length) {
      setIsGoogleAuthed(true);
    }
  }, [firebase]);

  return (
    <GoogleLogin
      clientId="653883513394-4ca68644a5c2iu2h5fg4s5t3a8f1qlco.apps.googleusercontent.com"
      buttonText={isGoogleAuthed ? 'Already Linked' : 'Link'}
      disabled={isGoogleAuthed}
      onSuccess={handleGoogleResponse}
      onFailure={handleGoogleResponse}
      cookiePolicy="single_host_origin"
      scope="profile email openid https://www.googleapis.com/auth/calendar.events.owned"
      accessType="offline"
      prompt="consent"
      responseType="code"
    />
  );
};

export default withFirebase(GoogleProvider);
