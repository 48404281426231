import React from 'react';
// import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'normalize.css';

import './index.css';
import store from './store';

import App from './containers/App';
import tracker from './utils/tracker';

tracker.register({
  Source: document.referrer || 'None',
  UserAgent: navigator.userAgent,
});

// Sentry.init({ dsn: 'https://35962e7f03834070a2393ebec4f3790d@sentry.io/4211030' });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
