import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/performance';
import { reactReduxFirebase } from 'react-redux-firebase';
import { compose, createStore, applyMiddleware } from 'redux';
// import { createLogger } from 'redux-logger';

import rootReducer from './reducers/rootReducer';

// Firebase config
const firebaseConfig = {
  apiKey: 'AIzaSyBSyFhdhYDIeuwNn6YImpCpP5tWpb5R2Dg',
  appId: '1:65049412922:web:892f7ad580a039ff507c03',
  authDomain: 'butterfly-production-89383.firebaseapp.com',
  databaseURL: 'https://butterfly-production-89383.firebaseio.com',
  measurementId: 'G-YW4WE72DVF',
  messagingSenderId: '65049412922',
  projectId: 'butterfly-production-89383',
  storageBucket: 'butterfly-production-89383.appspot.com',
};

const firebaseInstance = firebase.initializeApp(firebaseConfig);
firebaseInstance.analytics();
firebaseInstance.performance();
window.firebase = firebaseInstance;

// react-redux-firebase options
const config = {
  // userProfile: 'users', // firebase root where user profiles are stored
  enableLogging: false, // enable/disable Firebase's database logging
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

const middlewares = [];

/*
if (process.env.REACT_APP_RUN_TYPE === 'local') {
  const logger = createLogger({
    diff: true,
    collapsed: (getState, action, logEntry) => !logEntry.error,
  });
  middlewares.push(logger);
}
*/

// Add redux Firebase to compose
const createStoreWithFirebase = composeEnhancers(
  reactReduxFirebase(firebaseInstance, config),
  applyMiddleware(...middlewares),
)(createStore);

export default createStoreWithFirebase(rootReducer);
