import phoneFormatter from 'phone';
import React, { Component, Fragment } from 'react';
import { withFirebase } from 'react-redux-firebase';

import { createUser } from '../../services/whosup';
import tracker from '../../utils/tracker';

import './FirebasePhoneAuth.css';

export class FirebaseAuthComponent extends Component {
  constructor() {
    super();
    this.state = {
      captchaSolved: false,
      confirmationResult: '',
      name: '',
      phone: '',
      privacy: true,
      submitStatus: 'Please enter your name.',
      verify: '',
    };
  }

  UNSAFE_componentWillMount() {
    const { firebase, nextPage } = this.props;
    const { currentUser } = firebase.auth();
    if (currentUser && currentUser.displayName && currentUser.phoneNumber) {
      // user is already registered and authenticated
      tracker.track('OOA Create: User already authed with Firebase');
      tracker.identify(currentUser.displayName);
      nextPage();
    }
  }

  handleRef = (ref) => {
    if (ref) {
      const { firebase } = this.props;
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(ref, {
        callback: () => {
          this.setState(
            {
              captchaSolved: true,
            },
            this.validateInput,
          );
        },
        'expired-callback': () => {
          this.recaptchaVerifier.reset();
          this.setState(
            {
              captchaSolved: false,
            },
            this.validateInput,
          );
        },
        size: 'normal',
      });
      this.recaptchaVerifier.render();
    }
  };

  handleNameChange = ({ target: { value: name = '' } }) => {
    this.setState({ name }, this.validateInput);
  };

  handlePhoneChange = ({ target: { value: phone = '' } }) => {
    this.setState({ phone }, this.validateInput);
  };

  handlePrivacyCheck = ({ target: { checked: privacy = false } }) => {
    this.setState({ privacy }, this.validateInput);
  };

  handleResend = () => {
    this.setState({ confirmationResult: null, submitStatus: 'Please solve the captcha.' }, this.handleSubmit);
  };

  handleVerifyChange = ({ target: { value: verify = '' } }) => {
    this.setState({ verify }, this.validateInput);
  };

  handleSubmit = async () => {
    const { firebase, isInvite, nextPage } = this.props;
    const { confirmationResult, submitStatus, name, phone, verify } = this.state;
    if (submitStatus !== '') {
      alert(submitStatus || 'Something is missing.'); // eslint-disable-line no-alert
    } else if (!confirmationResult) {
      // user has entered their name and phone number
      const result = await firebase.auth().signInWithPhoneNumber(phone, this.recaptchaVerifier);
      this.setState({
        confirmationResult: result,
        submitStatus: 'Please enter the 6 digits sent to your mobile number.',
      });

      tracker.track(`OOA ${isInvite ? 'Invite' : 'Create'}: Enter Credentials`);
    } else {
      // user has entered their verification code
      let setAlias = false;
      await confirmationResult.confirm(verify);

      const { currentUser } = firebase.auth();
      if (!(currentUser && currentUser.displayName)) {
        // brand new user, put their uid as their displayName
        // and create an entry in the /users db with their display name
        const firstName = name.split(' ')[0];
        const lastName = name.replace(firstName, '');
        const userToken = await currentUser.getIdToken(true);
        await createUser({
          createdBy: 'widget',
          firstName,
          lastName,
          type: 'outOfApp',
          userToken,
        });
        setAlias = true;
        tracker.people({
          $created: new Date(),
          $first_name: firstName,
          $last_login: new Date(),
          $last_name: lastName,
          $phone: currentUser.phoneNumber,
          'Creation Type': isInvite ? 'Recipient' : 'Creator',
          Device: navigator.userAgent,
          'Last Open Date': new Date(),
          'User ID': currentUser.displayName || currentUser.uid,
        });
      }

      await firebase.auth().currentUser.reload();

      const { displayName } = firebase.auth().currentUser;
      if (setAlias) {
        tracker.alias(displayName);
      }
      tracker.identify(displayName);
      tracker.track(`OOA ${isInvite ? 'Invite' : 'Create'}: Enter Validation Code`);

      nextPage();
    }
  };

  validateInput = () => {
    const { captchaSolved, confirmationResult, name, phone, privacy, verify } = this.state;
    let submitStatus = false;
    let formattedPhone = phone;
    if (!confirmationResult) {
      if (name.length <= 1) {
        submitStatus = 'Please enter your name.';
      }
      if (!privacy) {
        submitStatus = 'You need to agree to our Terms of Service and Privacy Policy before proceeding.';
      }
      if (!captchaSolved) {
        submitStatus = 'Please solve the captcha.';
      }
      let [checkPhone] = phoneFormatter(phone);
      if (!checkPhone) {
        [checkPhone] = phoneFormatter(phone, 'CA');
      }
      if (checkPhone) {
        formattedPhone = checkPhone;
      } else {
        submitStatus = 'Invalid phone number.';
      }
      if (name.length > 1 && privacy && captchaSolved && checkPhone) {
        submitStatus = '';
      }
    } else if (verify.length === 6) {
      submitStatus = '';
    } else {
      submitStatus = 'Please enter the 6 digits sent to your mobile number.';
    }
    this.setState({
      phone: formattedPhone,
      submitStatus,
    });
  };

  render() {
    const { details } = this.props;
    const { confirmationResult, submitStatus, name, phone, privacy } = this.state;
    const isSubmitDisabled = submitStatus !== '';

    return (
      <div className="details signup">
        {!confirmationResult && (
          <Fragment>
            {/* <div className="headerEventTitle">{details.title || "Let's start your experience."}</div> */}
            <div className="details-copy">
              Before we get started,
              <br />
              we need to verify your info.
            </div>
            <input type="text" placeholder="Your Name" onChange={this.handleNameChange} value={name} />
            <div className="details-inputTitle">We will send you a verification code</div>
            <input
              type="text"
              pattern="[0-9]*"
              onChange={this.handlePhoneChange}
              placeholder="Mobile Number"
              value={phone}
            />
            {/* <div className="details-privacy">
              <input type="checkbox" onChange={this.handlePrivacyCheck} checked={privacy} />
              <div className="details-privacyText">
                By clicking this checkbox, you agree to our
                {' '}
                <br />
                <a href="https://www.fantic.io/terms" rel="noopener noreferrer" target="_blank">
                  Terms of Service
                </a>
                &nbsp;and&nbsp;
                <a href="https://www.fantic.io/privacy" rel="noopener noreferrer" target="_blank">
                  Privacy Policy
                </a>
              </div>
            </div> */}
          </Fragment>
        )}
        {confirmationResult && (
          <Fragment>
            <div className="details-copy">Almost done! We sent you an SMS. Please enter the 6-digit code.</div>
            {/* eslint-disable */}
            <input
              type="text"
              pattern="[0-9]*"
              placeholder="Verification code"
              autoFocus
              onChange={this.handleVerifyChange}
            />
            <br />
            <br />
          </Fragment>
        )}

        <div style={{ display: !confirmationResult ? 'block' : 'none' }}>
          <div className="details-recaptcha" ref={this.handleRef} />
          <button
            className={isSubmitDisabled ? 'disabled' : ''}
            onClick={this.handleSubmit}
            disabled={isSubmitDisabled}
          >
            Let's Go!
          </button>
          <p className="details-agreeSms">
            By tapping "Let's Go" you agree to Fantic's <b>Terms</b> and <b>Privacy Policy</b>, and to receive text
            messages in connection with this group.
          </p>
        </div>

        {confirmationResult && (
          <Fragment>
            <button
              className={isSubmitDisabled ? 'disabled' : ''}
              onClick={this.handleSubmit}
              disabled={isSubmitDisabled}
            >
              Verify
            </button>
            <div className="details-resendCode">
              Didn't receive a 6-digit code?
              <br />
              <div className="details-resendButton" onClick={this.handleResend}>
                Resend
              </div>
            </div>
          </Fragment>
        )}

        {/* {!confirmationResult && <div className="details-watchVerify">Watch for your 6-digit code</div>} */}
      </div>
    );
  }
}

export default withFirebase(FirebaseAuthComponent);
