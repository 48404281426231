import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { compose } from 'redux';

import { useScrollTop } from '../../hooks/useScrollTop';
import { outingLivestreamLinksSelector } from '../../selectors/outings';
import { userInfoSelector, userIdSelector } from '../../selectors/users';

import './LiveStreamLinks.css';

const LiveStreamLinksComponent = ({
  dispatch,
  footer,
  header = 'Here are the live streams links for show',
  livestreamLinks,
}) => {
  useScrollTop();
  const linkData = livestreamLinks.filter((link) => link.type === 'livestream');

  if (!linkData.length) {
    return null;
  }

  return (
    <div className="lsl">
      <div className="lsl-links-container">
        {header && <div className="lsl-links-header">{header}</div>}
        <div className="lsl-link-container">
          {linkData.map((link) => (
            <a key={link.description} href={link.url} target="_blank" rel="noopener noreferrer" className="lsl-link">
              {link.description}
            </a>
          ))}
        </div>
        {footer && <div className="lsl-link-footer">{footer}</div>}
      </div>
    </div>
  );
};

export default LiveStreamLinksComponent;
