export const bodyOrHtml = () => {
  if ('scrollingElement' in document) {
    return document.scrollingElement;
  }
  if (navigator.userAgent.indexOf('WebKit') !== -1) {
    return document.body;
  }
  return document.documentElement;
};

export const scrollTop = (time) => {
  setTimeout(() => {
    const element = bodyOrHtml();
    element.scrollTop = 0;
  }, time);
};

export const scrollBottom = (time) => {
  setTimeout(() => {
    const element = bodyOrHtml();
    element.scrollTop = element.scrollHeight;
  }, time);
};

export default {
  scrollBottom,
  scrollTop,
};
