import React, { Fragment } from 'react';
import Movie from './Movie';
import TourStop from './TourStop';

const EventComponent = ({ details, show }) => (
  <Fragment>
    {details.performers && (
      <TourStop
        address={show.includes('address') ? details.address : null}
        artist={show.includes('artist') ? { name: details.performers[0] } : null}
        date={show.includes('date') ? details.date : null}
        thumbnail={show.includes('date') ? details.thumbnail : null}
        venue={show.includes('venue') ? details.venue : null}
        title={show.includes('title') ? details.title : null}
      />
    )}
    {details.subTitle && (
      <Movie
        date={show.includes('movieDate') ? details.date : null}
        subTitle={show.includes('subTitle') ? details.subTitle : null}
        title={show.includes('title') ? details.title : null}
        thumbnail={details.thumbnail}
      />
    )}
  </Fragment>
);

export default EventComponent;
