import React, { useCallback, useEffect, useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { updateOauth, watchParty } from '../../services/whosup';
import { outingIdSelector } from '../../selectors/outings';
import GoogleProvider from '../Auth/Providers/Google';

import loadingDots from '../../images/loading_dots.gif';

const WatchPartyButton = ({ firebase, outingId }) => {
  const [isGoogleAuthed, setIsGoogleAuthed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleResponse = useCallback(
    async ({ code }) => {
      setIsLoading(true);
      try {
        let userToken = await firebase.auth().currentUser.getIdToken(true);
        const { id_token } = await updateOauth({ code, provider: 'google.com', userToken });

        const credential = firebase.auth.GoogleAuthProvider.credential(id_token);
        await firebase.auth().currentUser.linkWithCredential(credential);
        setIsGoogleAuthed(true);
        userToken = await firebase.auth().currentUser.getIdToken(true);
        await watchParty({ outingId, userToken });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [firebase, outingId],
  );

  useEffect(() => {
    const { currentUser } = firebase.auth();
    const googleDetails = currentUser.providerData.filter(({ providerId }) => providerId === 'google.com');
    if (googleDetails.length) {
      setIsGoogleAuthed(true);
    }
  }, [firebase]);

  return (
    <div>
      {!isGoogleAuthed && (
        <GoogleLogin
          clientId="653883513394-4ca68644a5c2iu2h5fg4s5t3a8f1qlco.apps.googleusercontent.com"
          buttonText={isGoogleAuthed ? 'Already Linked' : 'Link'}
          disabled={isGoogleAuthed}
          onSuccess={handleGoogleResponse}
          onFailure={handleGoogleResponse}
          cookiePolicy="single_host_origin"
          scope="profile email openid https://www.googleapis.com/auth/calendar.events.owned"
          accessType="offline"
          prompt="consent"
          render={(renderProps) => (
            <button
              className="lsi-enable-button"
              disable={`${renderProps.disabled}`}
              onClick={renderProps.onClick}
              type="button"
            >
              {!isLoading && 'Enable'}
              {isLoading && <img className="loading-dots" src={loadingDots} alt="loading-dots" />}
            </button>
          )}
          responseType="code"
        />
      )}
      {isGoogleAuthed && (
        <button
          className="lsi-enable-button"
          onClick={async () => {
            if (isLoading) {
              return;
            }
            setIsLoading(true);
            try {
              const userToken = await firebase.auth().currentUser.getIdToken(true);
              await watchParty({ outingId, userToken });
            } finally {
              setIsLoading(false);
            }
          }}
          type="button"
        >
          {!isLoading && 'Enable'}
          {isLoading && <img className="loading-dots" src={loadingDots} alt="loading-dots" />}
        </button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  outingId: outingIdSelector(state),
});

export default compose(withFirebase, connect(mapStateToProps))(WatchPartyButton);
