import moment from 'moment';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';

import './Bang.css';

import iconLeave from '../../images/icon-leave.svg';
import iconLocation from '../../images/icon-location.svg';
import iconPeople from '../../images/icon-people.svg';
import iconTime from '../../images/icon-time.svg';
import { outingLinksSelector, outingSuggestionsSelector } from '../../selectors/outings';
import { userAvatarSelector, userNameSelector } from '../../selectors/users';
import LiveStreamLinks from '../Outing/LiveStreamLinks';

/* TODO:
 * Move TYPE to a shared file
 * Refactor icon leave to come from bangIconFragment
 */

const TYPES = {
  ADDDATE: 'addDate',
  ATTENDING: 'attending',
  CHANGEDATE: 'changeDate',
  CHANGERESTAURANT: 'changeRestaurant',
  LIVESTREAMLINKS: 'livestreamLinks',
  MEETUP: 'meetup',
  MEMBERADDED: 'memberAdded',
  MEMBERLEFT: 'memberLeft',
  SELECTSHOWTIME: 'selectShowtime',
  SHOWTIME: 'showtime',
  SHOWTIMESUGGESTION: 'showtimeSuggestion',
  SUGGESTION: 'suggestion',
  SUGGESTRESTAURANT: 'suggestRestaurant',
};

const getBangIcon = (type, avatar) => {
  switch (type) {
    case TYPES.ATTENDING:
      return <img className="bang-avatar" src={avatar} alt="bang-avatar" />;
    case TYPES.MEMBERADDED:
      return <img src={iconPeople} className="bang-icon" alt={type} />;
    case TYPES.ADDDATE:
    case TYPES.CHANGEDATE:
    case TYPES.SELECTSHOWTIME:
      return <img src={iconTime} className="bang-icon" alt={type} />;
    case TYPES.CHANGERESTAURANT:
    case TYPES.SHOWTIMESUGGESTION:
    case TYPES.SHOWTIME:
    case TYPES.SUGGESTION:
    case TYPES.SUGGESTRESTAURANT:
      return <img src={iconLocation} className="bang-icon" alt={type} />;
    default:
      return '';
  }
};

class BangComponent extends Component {
  getBangText(type, name) {
    const {
      data: { arg0, suggestion, data, suggestionId },
      dispatch,
      links,
      suggestions,
    } = this.props;
    const timeChanged =
      (type === TYPES.CHANGEDATE || type === TYPES.ADDDATE) && moment(arg0).format('ddd, MMM D • h:mm A');
    const meetupLocation = suggestions && suggestions[suggestionId] && suggestions[suggestionId].updates.venueName;

    switch (type) {
      case TYPES.MEMBERADDED:
        return `${name} added to the outing`;
      case TYPES.MEMBERLEFT:
        return `${name} left the outing`;
      case TYPES.ATTENDING:
        return `${name} is going`;
      case TYPES.ADDDATE:
        return (
          <span>
            {name} set the event time to
            <br />
            {timeChanged}
          </span>
        );
      case TYPES.CHANGEDATE:
        return (
          <span>
            {name} changed the event time to
            <br />
            {timeChanged}
          </span>
        );
      case TYPES.CHANGERESTAURANT:
        return (
          <span>
            {name} changed the restaurant to
            <br />
            {arg0}
          </span>
        );
      case TYPES.SUGGESTRESTAURANT:
        return (
          <span>
            {name} suggests
            <br />
            {arg0}
          </span>
        );
      case TYPES.SUGGESTION:
        return (
          <span>
            {name} wants to meet at
            <br />
            {suggestion.name}
          </span>
        );
      case TYPES.MEETUP:
        return (
          <Fragment>
            <span>
              {name} wants to meet at
              <br />
              {meetupLocation}
            </span>
            <button
              onClick={(e) => {
                e.preventDefault();
                dispatch({ type: 'OPEN_MEET_UP_SUGGESTIONS' });
              }}
              type="button"
            >
              View All Suggested Meet Ups
            </button>
          </Fragment>
        );
      case TYPES.SELECTSHOWTIME:
        return (
          <Fragment>
            <span>{name} chose a showtime.</span>
            {links && (
              <button
                onClick={() => {
                  window.open(links[0].url);
                }}
                type="button"
              >
                Buy Tickets
              </button>
            )}
          </Fragment>
        );
      case TYPES.SHOWTIMESUGGESTION:
        return (
          <span>
            {name} suggests the following times:
            {Object.keys(data).map((date) => (
              <div key={date}>
                <br />
                <div>{date}</div>
                {Object.keys(data[date]).map((theater) => (
                  <div key={theater}>
                    <br />
                    <div>{theater}</div>
                    {Object.keys(data[date][theater]).map((showtime) => (
                      <div key={showtime}>{showtime}</div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </span>
        );
      case TYPES.SHOWTIME:
        return (
          <Fragment>
            <span>{name} suggested some movie showtimes</span>
            <button
              onClick={(e) => {
                e.preventDefault();
                dispatch({ type: 'OPEN_SHOWTIME_SUGGESTIONS' });
              }}
              type="button"
            >
              Go to Suggestions
            </button>
          </Fragment>
        );
      case TYPES.LIVESTREAMLINKS:
        return <LiveStreamLinks footer={data.footer} header={data.header} links={links} />;
      default:
        return '';
    }
  }

  render() {
    const {
      avatar,
      data: { timestamp, type, suggestionType },
      name,
    } = this.props;
    const bangIconFragment = getBangIcon(type, avatar);
    const bangTextFragment = this.getBangText(suggestionType || type, name);
    const time = moment(timestamp).format('LT');

    return (
      <div className="bang">
        <div className="bang-header">{bangIconFragment}</div>
        <div className="bang-text">
          {type === TYPES.MEMBERLEFT && (
            <div className="bang-icon-leave">
              <img src={iconLeave} alt={type} />
            </div>
          )}
          {bangTextFragment}
        </div>
        <div className="bang-timestamp">{time}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, { data }) => ({
  avatar: userAvatarSelector(state, data),
  links: outingLinksSelector(state, data),
  name: userNameSelector(state, data),
  suggestions: outingSuggestionsSelector(state, data),
});

export default connect(mapStateToProps)(BangComponent);
