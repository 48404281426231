import React from 'react';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import Message from './Message';
import Bang from './Bang';

const MessageContent = ({ content, isInvite }) => {
  if (content.type && content.type !== 'standard') {
    return <Bang data={content} />;
  }
  return <Message data={content} />;
};

const mapStateToProps = null;

export const MessageContentInvite = connect(mapStateToProps)(MessageContent);

export default compose(
  // connect to firebase here because we might get messages from users that aren't in the members list
  firebaseConnect(({ content: { user } }) => [{ path: `users/${user}/info` }]),
  connect(mapStateToProps),
)(MessageContent);
