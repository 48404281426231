import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { outingCustomCopySelector, outingIdSelector, outingsSelector } from '../../selectors/outings';
import { isMobile } from '../../utils/userAgent';

import groupIcon from '../../images/Event/group.svg';
import inviteIcon from '../../images/Event/invite.svg';
import movieIcon from '../../images/Event/movie.png';
import noAppIcon from '../../images/Event/noApp.png';
import ticketsIcon from '../../images/Event/ticketicon.png';
import smsIcon from '../../images/Event/texticon.svg';
import emailIcon from '../../images/Event/emailicon.svg';
import messengerIcon from '../../images/Event/messengericon.svg';
import whatsappIcon from '../../images/Event/whatsappicon.svg';

import avatar1 from '../../images/Event/avatar1.png';
import avatar2 from '../../images/Event/avatar2.png';
import avatar3 from '../../images/Event/avatar3.png';

import './GetStarted.css';

const defaults = {
  bullet1_icon: inviteIcon,
  bullet1_text: 'Plan Outings With Friends',
  bullet2_icon: groupIcon,
  bullet2_text: 'Private Group Chat',
  bullet3_icon: ticketsIcon,
  bullet3_text: 'Vote On Showtimes, Get Tickets',
  bullet4_icon: movieIcon,
  bullet4_text: 'Exclusive Content',
  getStartedButton: 'Get Started',
  getStartedHeader: 'Group Outings Made Easy',
};

const GetStartedComponent = ({ customCopy, isInvite, hasOutings, nextPage, setInviteType, outing }) => {
  if (outing) {
    defaults.getStartedButton = 'Join Private Group';
    customCopy = customCopy.getStarted; // eslint-disable-line
  }
  const pageCopy = Object.assign({}, defaults, customCopy);
  const bullets = {};

  Object.keys(pageCopy).forEach((key) => {
    if (key.includes('bullet')) {
      const [bulletKey, bulletValue] = key.split('_');
      bullets[bulletKey] = Object.assign({}, bullets[bulletKey], {
        [bulletValue]: pageCopy[key],
      });
    }
  });

  const next = (inviteType) => {
    setInviteType(inviteType);
    nextPage();
  };

  return (
    <div className="details-getStarted">
      <div className="details-content">
        {/* <div className="details-copy">
          {pageCopy.getStartedHeader}
        </div> */}
        {!isInvite && isMobile && (
          <Fragment>
            <div className="details-copy">Don&apos;t go alone, invite your friends to join you!</div>
            <div className="details-shares">
              <button className="shareOption" onClick={() => next('sms')} type="button">
                <img src={smsIcon} alt="SMS" />
                <span>Text Message</span>
              </button>
              <button className="shareOption" onClick={() => next('whatsapp')} type="button">
                <img src={whatsappIcon} alt="WhatsApp" />
                <span>WhatsApp</span>
              </button>
              <button className="shareOption" onClick={() => next('messenger')} type="button">
                <img src={messengerIcon} alt="Messenger" />
                <span>Messenger</span>
              </button>
              <button className="shareOption" onClick={() => next('email')} type="button">
                <img src={emailIcon} alt="Email" />
                <span>Email</span>
              </button>
              {!hasOutings && (
                <div className="shareOption skip" onClick={() => next('skip')}>
                  Skip - Invite friends later
                </div>
              )}
            </div>
          </Fragment>
        )}
        {!isInvite && !isMobile && (
          <button className="desktopContinue" onClick={() => next('skip')} type="button">
            Continue
          </button>
        )}
        <div className="invited">
          <h2>You&apos;ve been invited!</h2>
        </div>
        <div className="avatarContainer">
          <div className="avatarPics">
            <span className="avatarPic">
              <img src={avatar3}></img>
            </span>
            <span className="avatarPic">
              <img src={avatar2}></img>
            </span>
            <span className="avatarPic">
              <img src={avatar1}></img>
            </span>
          </div>
        </div>
        {isInvite && (
          <button className="getStarted" onClick={nextPage} type="button">
            {pageCopy.getStartedButton}
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  customCopy: props.customCopy || outingCustomCopySelector(state, props) || {},
  hasOutings: Object.keys(outingsSelector(state)).length > 0,
  outing: outingIdSelector(state, props),
});

export default connect(mapStateToProps)(GetStartedComponent);
