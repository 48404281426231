import moment from 'moment';
import React from 'react';
import avatar1 from '../../images/Event/avatar1.png';
import avatar2 from '../../images/Event/avatar2.png';
import avatar3 from '../../images/Event/avatar3.png';
import store from '../../store';

import './TourStop.css';

const TourStop = ({ address, artist, date, title, thumbnail, venue, onClick }) => {
  const formattedDate = moment(date).format('ddd MMM Do @ h:mm a');
  const formattedAddress = address && address.city && address.state_abbr && `${address.city}, ${address.state_abbr}`;

  let line1 = artist.name || artist;
  let line2 = (date && formattedDate) || '';
  let line3 = (venue && venue.name) || '';

  if (formattedAddress) {
    line1 = (venue && venue.name) || '';
    line2 = formattedAddress;
    if (title) {
      line1 = title;
      line2 = (venue && venue.name) || '';
    }
    line3 = formattedDate;
  }

  const handleCreateClick = () => {
    store.dispatch({ type: 'OPEN_INVITE_FRIENDS_OVERLAY' });
    onClick && onClick();
  };

  return (
    <div className={line2 ? 'tour-stop-lg' : 'tour-stop'}>
      {thumbnail && (
        <div className={line2 ? 'tour-avatar-lg' : 'tour-avatar'}>
          <img src={thumbnail} alt={artist.name || artist} />
        </div>
      )}
      <div className="tour-text">
        <div className="line1">{line1}</div>
        <div className="line2">{line2}</div>
        <div className="line3">{line3}</div>
        <div className="tour-click">
          <button onClick={handleCreateClick} type="button" className="text">
            Create
          </button>
        </div>
        <div className="profilePicsContainer">
          <div className="profilePics">
            <div className="profilePic">
              <img src={avatar3}></img>
            </div>
            <div className="profilePic">
              <img src={avatar2}></img>
            </div>
            <div className="profilePic">
              <img src={avatar1}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourStop;
