import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';

mixpanel.init('db33b45fae535228cf18331527a6e246');
posthog.init('phc_nMKgcekPm6zrS62dD8CNKIEf3xUtM10yXusgMCbST7n', {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'identified_only',
});

const actions = {
  alias: (id) => mixpanel.alias(id),
  identify: (id) => {
    mixpanel.identify(id);
    posthog.identify(id);
  },
  increment: (props) => mixpanel.people.increment(props),
  people: (props) => mixpanel.people.set(props),
  register: (props) => mixpanel.register(props),
  track: (name, props) => {
    mixpanel.track(name, props);
    posthog.capture(name, props);
  },
  union: (props) => mixpanel.people.union(props),
};

export default actions;
