import React from 'react';
import { fanticUrl } from '../utils/fanticUrl';

import './Main.css';

const MainComponent = () => {
  if (process.env.REACT_APP_RUN_TYPE === 'local') {
    return (
      <div>
        <a href="http://localhost:3000/#!/event/23-24-cycle-season">Test Event</a>
        <br />
        <br />
        <a href="http://localhost:3000/#!/invite/Kkuu-6bYN">Test Invite</a>
        <br />
        <br />
        <a href="http://localhost:3000/#!/gtuOG91sh">Test Outing</a>
      </div>
    );
  }

  window.location.replace('https://www.fantic.io/');

  return null;
};

export default MainComponent;
