import React from 'react';
import { connect } from 'react-redux';
import { firebaseConnect, isEmpty } from 'react-redux-firebase';
import { compose } from 'redux';

import Loading from './Loading';
import { outingMembersSelector } from '../../selectors/outings';

const LoadFirebaseOuting = ({ isFullyLoaded, children }) => {
  if (!isFullyLoaded) {
    return <Loading />;
  }
  return children;
};

export default compose(
  firebaseConnect(({ outing, user }) => [{ path: `outings/${outing}` }, { path: `users/${user}` }]),
  connect((state, props) => ({
    members: outingMembersSelector(state, props) || [],
  })),
  firebaseConnect(({ members }) => [...members.map(({ key }) => ({ path: `users/${key}/info` }))]),
  connect((state, { outing, members }) => {
    const { outings, users } = state.firebase.data;
    const outingData = !isEmpty(outings) && outings[outing];
    const isOutingLoaded = !isEmpty(outingData) && !isEmpty(outingData.info);
    let areMembersLoaded = true;

    if (!users || !Array.isArray(members) || members.length === 0) {
      areMembersLoaded = false;
    } else {
      members.forEach(({ key }) => {
        if (isEmpty(users[key])) {
          areMembersLoaded = false;
        }
      });
    }

    return {
      isFullyLoaded: areMembersLoaded && isOutingLoaded,
    };
  }),
)(LoadFirebaseOuting);
