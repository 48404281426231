let shouldShowInviteFriendsOverlay = false;

try {
  shouldShowInviteFriendsOverlay = JSON.parse(localStorage.getItem('showDetailsModal')) || false;
  localStorage.removeItem('showDetailsModal');
} catch (e) {
  shouldShowInviteFriendsOverlay = false;
}

const initialState = {
  isAddFlowOpen: false,
  isCoverOpen: true,
  isDetailsOpen: false,
  isGroupListOpen: false,
  isInviteFlowOpen: false,
  isInviteFriendsOverlayOpen: shouldShowInviteFriendsOverlay,
  isLiveStreamInfoOpen: false,
  isLocationOpen: false,
  isMeetUpSuggestionsOpen: false,
  isProfileOpen: false,
  isSelectMovieTimeOpen: false,
  isShowtimeSuggestionsOpen: false,
  isSuggestMeetUpOpen: false,
  isSuggestMovieTimesOpen: false,
  source: '',
};

function UIControlReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_COVER':
      return { ...initialState };
    case 'OPEN_DETAILS':
      return {
        ...state,
        isCoverOpen: false,
        isDetailsOpen: true,
      };
    case 'OPEN_PROFILE':
      return Object.assign({}, state, {
        isCoverOpen: false,
        isProfileOpen: true,
      });
    case 'CLOSE_PROFILE':
      return Object.assign({}, state, {
        isCoverOpen: true,
        isProfileOpen: false,
      });
    case 'OPEN_SUGGEST_MEET_UP':
      return Object.assign({}, state, {
        isCoverOpen: false,
        isSuggestMeetUpOpen: true,
      });
    case 'CLOSE_SUGGEST_MEET_UP':
      return Object.assign({}, state, {
        isCoverOpen: true,
        isSuggestMeetUpOpen: false,
      });
    case 'OPEN_SUGGEST_MOVIE_TIMES':
      return Object.assign({}, state, {
        isCoverOpen: false,
        isSuggestMovieTimesOpen: true,
      });
    case 'CLOSE_SUGGEST_MOVIE_TIMES':
      return Object.assign({}, state, {
        isCoverOpen: true,
        isSuggestMovieTimesOpen: false,
      });
    case 'OPEN_SELECT_MOVIE_TIME':
      return Object.assign({}, state, {
        isCoverOpen: false,
        isDetailsOpen: false,
        isSelectMovieTimeOpen: true,
      });
    case 'CLOSE_SELECT_MOVIE_TIME':
      return Object.assign({}, state, {
        isCoverOpen: true,
        isSelectMovieTimeOpen: false,
      });
    case 'OPEN_SHOWTIME_SUGGESTIONS':
      return Object.assign({}, state, {
        isCoverOpen: false,
        isDetailsOpen: false,
        isShowtimeSuggestionsOpen: true,
      });
    case 'CLOSE_SHOWTIME_SUGGESTIONS':
      return Object.assign({}, state, {
        isCoverOpen: true,
        isShowtimeSuggestionsOpen: false,
      });
    case 'OPEN_MEET_UP_SUGGESTIONS':
      return Object.assign({}, state, {
        isCoverOpen: false,
        isDetailsOpen: false,
        isMeetUpSuggestionsOpen: true,
      });
    case 'CLOSE_MEET_UP_SUGGESTIONS':
      return Object.assign({}, state, {
        isCoverOpen: true,
        isMeetUpSuggestionsOpen: false,
      });
    case 'OPEN_INVITE_FLOW':
      return Object.assign({}, state, {
        isInviteFlowOpen: true,
      });
    case 'CLOSE_INVITE_FLOW':
      return Object.assign({}, state, {
        isInviteFlowOpen: false,
      });
    case 'OPEN_LIVESTREAM_INFO':
      return Object.assign({}, state, {
        isCoverOpen: false,
        isDetailsOpen: false,
        isLiveStreamInfoOpen: true,
      });
    case 'CLOSE_LIVESTREAM_INFO':
      return Object.assign({}, state, {
        isCoverOpen: true,
        isLiveStreamInfoOpen: false,
      });
    case 'OPEN_ADD_FLOW':
      return Object.assign({}, state, {
        isAddFlowOpen: true,
        isCoverOpen: false,
        isDetailsOpen: true,
        source: action.source || 'details',
      });
    case 'CLOSE_ADD_FLOW':
      return Object.assign({}, state, {
        isAddFlowOpen: false,
        isCoverOpen: state.source === 'cover',
        isDetailsOpen: state.source !== 'cover',
        source: '',
      });
    case 'OPEN_INVITE_FRIENDS_OVERLAY':
      localStorage.setItem('showDetailsModal', true);
      return Object.assign({}, state, {
        isInviteFriendsOverlayOpen: true,
      });
    case 'CLOSE_INVITE_FRIENDS_OVERLAY':
      localStorage.removeItem('showDetailsModal');
      initialState.isInviteFriendsOverlayOpen = false;
      return Object.assign({}, state, {
        isInviteFriendsOverlayOpen: false,
      });
    case 'OPEN_LOCATION':
      return Object.assign({}, state, {
        isCoverOpen: false,
        isDetailsOpen: true,
        isLocationOpen: true,
      });
    case 'OPEN_GROUP_LIST':
      return Object.assign({}, state, {
        isCoverOpen: false,
        isDetailsOpen: true,
        isGroupListOpen: true,
      });
    default:
      return state;
  }
}

export default UIControlReducer;
