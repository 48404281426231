import React from 'react';

import { isFacebook, isMobile } from '../../utils/userAgent';

const InviteCreated = ({ currentAction, inviteType, message, redirectLink, url }) => (
  <div className="details create">
    {currentAction && <div className="details-action">{currentAction}</div>}
    {redirectLink && inviteType !== 'skip' && (
      <div className="details-sms">
        <div className="details-created-header">Your personal invitation!</div>
        <p>
          If your messaging app didn&apos;t open, click the button below and paste as a text on{' '}
          {inviteType !== 'messenger' && 'SMS, WhatsApp, or '}Messenger
        </p>
        <button className="copy" data-clipboard-text={message} type="button">
          Copy Invitation
        </button>
        <div className="details-created-sub">Text will appear as follows:</div>
        <textarea onFocus={(ev) => ev.target.select()} readOnly value={message} />
      </div>
    )}
  </div>
);

export default InviteCreated;
