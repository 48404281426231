import { createSelector } from 'reselect';

export const userIdSelector = (state, props = {}) =>
  props.userId || props.user || state.firebase.auth.displayName || state.firebase.auth.uid;
export const usersSelector = (state) => (state.invite.isInvite && state.invite.data.users) || state.firebase.data.users;

export const userSelector = createSelector(
  usersSelector,
  userIdSelector,
  (users, userId) => users && userId && users[userId],
);

export const userInfoSelector = createSelector(userSelector, (user) => user && user.info);

export const userSettingsSelector = createSelector(userSelector, (user) => user && user.settings);

export const userAvatarSelector = createSelector(userInfoSelector, (userInfo) => userInfo && userInfo.avatar);

export const userNameSelector = createSelector(
  userInfoSelector,
  (userInfo) => userInfo && `${userInfo.firstName}${userInfo.lastName ? ` ${userInfo.lastName}` : ''}`,
);
